import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ApiClient from '../../core/apiClient';
import CheckAuth from '../../core/checkAuth';
import Icon from '../Icon';
import MainInfo from './MainInfo';
import Popup from '../Popup';
import Button from '../Button';

const MobileProfile = observer(() => {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const handelOpenPopup = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setPopup(!popup);
  };

  const logoutClick = () => {
    setLoading(true);

    ApiClient.logout().then(() => {
      CheckAuth.logout();

      setLoading(false);
    }).catch(() => setLoading(false));
  };

  return (
    <div className="profile-menu">
      <MainInfo />
      <div className="profile-tabs">
        <NavLink to="/profile" exact activeClassName="active">
          <div className="tab">
            <div>
              <Icon name="profile" />
              {t('Profile')}
            </div>
            <div>
              <Icon name="profile-arrow-right" />
            </div>
          </div>
        </NavLink>
        <NavLink to="/profile/account" exact activeClassName="active">
          <div className="tab">
            <div>
              <Icon name="account" />
              {t('Account')}
            </div>
            <div>
              <Icon name="profile-arrow-right" />
            </div>
          </div>
        </NavLink>
        <div className="separators" />
        <NavLink to="/profile/monitorings" exact activeClassName="active">
          <div className="tab">
            <div>
              <Icon name="monitorings" />
              {t('Monitorings')}
            </div>
            <div>
              <Icon name="profile-arrow-right" />
            </div>
          </div>
        </NavLink>
        {/*<NavLink to="/profile/subscriptions" exact activeClassName="active">*/}
        {/*  <div className="tab"><Icon name="subscriptions" />Subscriptions</div>*/}
        {/*</NavLink>*/}
        <NavLink to="/profile/billing" exact activeClassName="active">
          <div className="tab">
            <div>
              <Icon name="billing" />
              {t('Billing')}
            </div>
            <div>
              <Icon name="profile-arrow-right" />
            </div>
          </div>
        </NavLink>
        <div className="separators" />
        <NavLink to="/profile/notifications" exact activeClassName="active">
          <div className="tab">
            <div>
              <Icon name="bell" />
              {t('Notifications')}
            </div>
            <div>
              <Icon name="profile-arrow-right" />
            </div>
          </div>
        </NavLink>
        <NavLink to="/profile/messaging" exact activeClassName="active">
          <div className="tab">
            <div>
              <Icon name="messaging" />
              {t('Messaging')}
            </div>
            <div>
              <Icon name="profile-arrow-right" />
            </div>
          </div>
        </NavLink>
        <div className="separators" />
        <NavLink exact to="/help" activeClassName="active">
          <div className="tab">
            <div>
              <Icon name="help-center" />
              {t('Help Center')}
            </div>
            <div>
              <Icon name="profile-arrow-right" />
            </div>
          </div>
        </NavLink>
        <div className="separators" />
        <div className="tab" onClick={handelOpenPopup}>
          <div>
            <Icon name="logout" />{t('Log Out')}
          </div>
        </div>
        {popup ? (
          <Popup onClose={handelOpenPopup}>
            <div className="popup-logOut">
              <p className="t-headline-3">{t('Are you sure you want to log out of your account?')}</p>
              <div className="buttons">
                <Button
                  text={t('Log Out')}
                  size="big"
                  bc="red"
                  loading={loading}
                  onClick={() => logoutClick()}
                />
                <Button text={t('Cancel')} size="big" bc="transparent" onClick={() => handelOpenPopup()} />
              </div>
            </div>
          </Popup>
        ) : null}
      </div>
    </div>
  );
});

export default MobileProfile;
