import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IMonitor } from '../../interfaces/monitor';
import getHM from '../../scripts/getHM';

interface IFC {
  monitor: IMonitor;
}

const OverviewAvail = ({ monitor }: IFC) => {
  const { t } = useTranslation();

  const RenderType = (value: string, type: string) => {
    switch (type) {
      case 'text':
        return t(`${value}`);
      case 'percent':
        return `${parseInt(value, 10)}%`;
      case 'seconds':
        return getHM(Number(value));
      default:
        return t('N/A');
    }
  };

  return (
    <div className="overview">
      <div className="t-headline-4">{t('Overview')} <span>sync every 5 minutes</span></div>
      <div className="overview-content">
        {monitor?.overview?.map((col) => (
          <div className="overview-col">
            {col?.map((item: any) => (
              <div className="overview-line">
                <div className="t-label-1">{item?.name}</div>
                <div className="overview-line-wrapper">
                  {item?.data.map((item: any) => (
                    <div className="overview-block">
                      <div className={`block__value t-headline-4 ${item?.level === 'ok' ? 'green' : (item?.level === 'error') && 'red'}`}>
                        {RenderType(item?.value, item?.type)}
                      </div>
                      <div className="block__name t-label-2">{item?.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OverviewAvail;
