import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import appStore from 'mobx/app';
import ApiClient from '../../core/apiClient';
import CheckAuth from '../../core/checkAuth';
import Button from '../Button';
import Input from '../Form/Input';
import Field from '../Form/Field';

const SignIn = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ email: '', password: '', lang: i18n.language === 'ru-RU' ? 'ru' : 'en' });
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [errorTextEmail, setErrorTextEmail] = useState<string>('');
  const [errorTextPassword, setErrorTextPassword] = useState<string>('');

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const submit = () => {
    setLoading(true);
    setErrorTextPassword('');
    setErrorPassword(false);
    setErrorTextEmail('');
    setErrorEmail(false);

    ApiClient.login({ ...form }).then((res: any) => {
      if (res?.data) {
        ApiClient.setToken(res.data?.accessToken, res.data?.refreshToken);
        CheckAuth.login();
        if (appStore.redirectTo) {
          const redirectTo = appStore.redirectTo;
          appStore.setRedirectTo(null);
          history.push(redirectTo);
        }
      }

      if (res.response.data.msg) {
        if (typeof res.response.data.msg === 'object') {
          Object.keys(res.response.data.msg).forEach((err: string) => {
            if (err === 'password') {
              setErrorTextPassword(res.response?.data.msg[err][0]);
              setErrorPassword(true);
            } else if (err === 'email') {
              setErrorTextEmail(res.response?.data.msg[err][0]);
              setErrorEmail(true);
            }
          });
        }
      }

      setLoading(false);
    }).catch(() => setLoading(false));
  };

  return (
    <div className="auth-main">
      <div className="auth-main__first-column">
        <div className="sign-in">
          <h2 className="t-headline-2">{t('Sign In')}</h2>
          <div className="subtitle">
            <p className="t-subtitle-2">{t('Don’t have an account?')}</p>
            <p className="t-button"><Link to="/sign-up">{t('Sign Up')}</Link></p>
          </div>
          <div className="form">
            <Field label={t('Email')} disabled={loading} error={errorEmail} errorText={errorTextEmail}>
              <Input
                name="email"
                placeholder={t('Enter email')}
                icon="email"
                cleanable
                value={form.email}
                onChange={changeHandler}
                onEnter={submit}
              />
            </Field>
            <Field
              label={t('Password')}
              helperText={t('Minimum 8 characters')}
              errorText={errorTextPassword}
              error={errorPassword}
              disabled={loading}
            >
              <>
                <Link to="/reset-password" className="forgot-password">{t('Forgot password?')}</Link>
                <Input
                  name="password"
                  placeholder={t('Enter password')}
                  icon="lock"
                  cleanable
                  value={form.password}
                  onChange={changeHandler}
                  onEnter={submit}
                />
              </>
            </Field>
            <Button
              text={t('Sign In')}
              size="big"
              bc="indigo"
              iconLeft="sign-in-out"
              onClick={submit}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div className="auth-main__second-column" />
    </div>
  );
};

export default SignIn;
