import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Accordion from '../Accordion';

interface TransactionProps {
  date: string,
  from: number,
  to: number,
  comment: string,
  currency: string,
  amount: string,
  status: number
}

const Billing: React.FC<TransactionProps> = observer(({ amount, comment, to }) => {
  const { t } = useTranslation();

  return (
    <div className="summary__inner">
      <div className="summary-item">
        <div className="summary-item__block">
          <Accordion title={comment} price={amount} desc={t('details')} to={to}>
            <div>
              <p>{comment}</p>
              <p>{amount}</p>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
});

export default Billing;
