import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FSPopup from '../FSPopup';
import GraphQLCreate from './GraphQL';
import ElasticCreate from './Elastic';
import HealthCheckCreate from './HealthCheck';

const SimpleMonitoring = () => {
  const { t } = useTranslation();

  return (
    <FSPopup back={t('Return')}>
      <div className="simple-monitoring">
        <div className="sequence">
          <div className="active"><p>1</p> {t('Installation')}</div>
          <div><p>2</p> {t('Subscription')}</div>
        </div>
        <Switch>
          <Route exact path="/monitoring/create/graphql">
            <GraphQLCreate />
          </Route>
          <Route exact path="/monitoring/create/elastic">
            <ElasticCreate />
          </Route>
          <Route exact path="/monitoring/create/health-check">
            <HealthCheckCreate />
          </Route>
        </Switch>
      </div>
    </FSPopup>
  );
};

export default SimpleMonitoring;
