import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useRoutes } from '../../routes/routes';
import 'react-toastify/dist/ReactToastify.css';
import CheckAuth from '../../core/checkAuth';
import history from '../../core/history';
import AppStore from '../../mobx/app';
import MeStore from '../../mobx/me';

const App = observer(() => {
  const [theme, setTheme] = useState<string>();

  useEffect(() => {
    setTheme(MeStore.me?.theme ?? AppStore.theme);
  }, [MeStore.me?.theme]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (theme === 'light') {
        body.classList.remove('app--dark');
      } else {
        body.classList.add('app--dark');
      }
    }
  }, [theme]);

  useEffect(() => {
    CheckAuth.checkLocalStorage();
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.overflowY = AppStore.isPopupOpen ? 'hidden' : 'auto';
    }
  }, [AppStore.isPopupOpen]);
  const body = document.querySelector('body');

  window.addEventListener('popstate', () => {
    const fspopup = document.querySelector('.fspopup');

    if (fspopup) {
      AppStore.setPopupOpened();
    } else {
      AppStore.setPopupClosed();
    }
  });

  useEffect(() => {
    if (body) {
      body.style.overflowY = AppStore.isPopupOpen ? 'hidden' : 'auto';
    }
  }, [AppStore.isPopupOpen]);

  const routes = useRoutes(CheckAuth.isAuthenticated);

  if (AppStore.redirectTo) {
    history.replace('/sign-in');
  }

  return (
    <Router history={history}>
      <div className={`app ${CheckAuth.isAuthenticated ? '' : 'app_auth'}`}>
        {routes}
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Router>
  );
});

export default App;
