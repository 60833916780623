import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { IEvents } from 'interfaces/event';
import { useTranslation } from 'react-i18next';
import EventsStore from '../../mobx/events';
import ItemNotification from './ItemNotification';
import Scroll from '../Scroll';

const DayNotification: React.FC = observer(() => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [events, setEvents] = useState(EventsStore.events);

  useEffect(() => {
    if (EventsStore.eventTab !== 'all') {
      setEvents(EventsStore.events.filter((e) => e.level === EventsStore.eventTab));
    } else {
      setEvents(EventsStore.events);
    }
  }, [EventsStore.eventTab, EventsStore.events]);

  useEffect(() => {
    EventsStore.getListEvents();
  }, []);

  let lastDate = '';
  const isToday = moment().toISOString();

  const days = (item: IEvents, index:number, last: boolean) => {
    const date = item.created_at.substring(0, 10);
    const renderDate = date !== lastDate;

    lastDate = date;

    return (
      <div key={`days-${date}-${index}`}>
        {renderDate && <div className="notification__date t-subtitle-2">{moment(isToday).isSame(item.created_at, 'day') ? `${t('Today')}, ${moment(item.created_at).format('DD MMM, YYYY')}` : moment(item.created_at).format('DD MMM, YYYY')}</div>}
        <ItemNotification key={`events${item}`} title={item.title} description={item.description} type={item.type} time={item.created_at} nameMonitor={item.monitor.name} typeMonitor={item.monitor.type} knowledge_id={item?.knowledge_id} countNew={EventsStore.eventsNew} last={last} />
      </div>
    );
  };

  let countNew = 0;

  return (
    <>
      <div className="notification__container" ref={ref}>
        {events.map((event, index) => {
          countNew += 1;
          if (EventsStore.eventsNew === countNew) {
            return days(event, index, true);
          }
          return days(event, index, false);
        })}
      </div>
      <Scroll container={ref} />
    </>
  );
});

export default DayNotification;
