import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import ApiClient from '../core/apiClient';
import { IMe } from '../interfaces/me';
import { IPayments } from '../interfaces/payments';
import { IPaymentMethod } from '../interfaces/paymentMethod';

class MeStore {
  me: IMe | null = null;

  payment: IPayments[] = [];

  isLoading: boolean | string = false;

  isTime: number = 0;

  isTimeActive: boolean = false;

  codeLoading: boolean = false;

  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  getMe() {
    ApiClient.getMe().then((res) => {
      this.setMe(res?.data || null);
    });
  }

  getPayment(from: string, to: string) {
    ApiClient.getPayment(from, to).then((res) => {
      this.payment = res?.data?.transactions;
    });
  }

  updateMe(data: any, loading: string) {
    this.isLoading = loading;
    ApiClient.updateMe(data).then((res) => {
      if (res.data?.user) {
        this.me = { ...this.me, ...res.data?.user };
        toast.success('Successfully updated');
      }
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  }

  loadFile(file: any) {
    this.isLoading = 'avatarUpload';
    const formData = new FormData();

    formData.append('avatar', file[0]);

    ApiClient.uploadAvatar(formData).then((res) => {
      this.me!.avatar = res.data.avatar;
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  }

  setTelegram(telegramId: number) {
    this.isLoading = 'addTelegram';
    ApiClient.updateMe({ telegram_id: telegramId }).then((res) => {
      if (res.data?.user) {
        this.setMe(res.data?.user);
        toast.success('Telegram successfully connected');
      }
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  }

  addPhone(phone: string) {
    this.isLoading = 'addedPhone';
    this.loading = true;
    ApiClient.addPhone(phone).then((res) => {
      this.loading = false;
      this.isLoading = false;
      if (res?.status === 201) {
        toast.success('phone number added');
        this.isTime = 60;
        this.isTimeActive = true;
      }
    }).catch(() => {
      this.isLoading = false;
      this.loading = false;
    });
  }

  deletePhone(phone: string) {
    this.isLoading = 'deletedPhone';
    this.loading = true;
    ApiClient.deletePhone(phone).then((res) => {
      this.loading = false;
      this.isLoading = false;
      if (res?.status === 201) {
        toast.success('phone number deleted');
      }
    }).catch(() => {
      this.isLoading = false;
      this.loading = false;
    });
  }

  validatePhone(phone: string, code: number) {
    this.isLoading = 'validatedPhone';
    this.codeLoading = true;
    ApiClient.validatePhone(phone, code).then((res) => {
      this.codeLoading = false;
      this.isLoading = false;
      if (res?.status === 201) {
        toast.success('phone number validated');
        this.me?.phones?.push({ id: Number(phone), phone });
      }
    }).catch(() => {
      this.codeLoading = false;
      this.isLoading = false;
    });
  }

  removeTelegram() {
    this.isLoading = 'removingTelegram';
    ApiClient.updateMe({ telegram_id: 0 }).then((res) => {
      if (res.data?.user) {
        this.setMe(res.data?.user);
        toast.success('Telegram account unconnected');
      }
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  }

  setMe(data: IMe | null) {
    this.me = data;
  }

  setPayment(payment: IPayments[]) {
    this.payment = payment;
  }

  setPaymentMethods(methods: IPaymentMethod[]) {
    if (this.me) {
      this.me.payment = methods;
    }
  }

  removePaymentMethod(methodId: number) {
    if (this.me && this.me.payment) {
      this.me.payment = this.me.payment.filter((method) => method.id !== methodId);
    }
  }
}

export default new MeStore();
