import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import FSPopup from '../FSPopup';

const Messaging = observer(() => {
  const { t } = useTranslation();

  return (
    <FSPopup back="Return" close="/profile/messaging">
      <div className="agent-monitoring">
        <div className="agent-monitoring__title t-headline-2">{t('Connect Telegram for Messaging')}</div>
        <div className="agent-monitoring__desc t-input">{t('Follow instructions to setup telegram')}</div>
        <form>
          <div className="agent-monitoring__block">
            <div className="agent-monitoring__block-title">1. {t('Add @WhatchaMeBot in telegram')}</div>
            <div className="agent-monitoring__block-inner">
              <p className="agent-monitoring__block-text t-body-2">
                <a href="https://t.me/WhatchaMeBot" className="agent-monitoring__share" rel="noreferrer" target="_blank">{t('Follow the link:')} https://t.me/WhatchaMeBot <Icon name="share" /></a>
              </p>
            </div>
          </div>
          <div className="agent-monitoring__block">
            <div className="agent-monitoring__block-title">2. {t('Type /auth in Telegram ')}</div>
            <div className="agent-monitoring__block-inner">
              <p className="agent-monitoring__block-text t-body-2">{t('Bot will give you link to complete connection')}</p>
            </div>
          </div>
          <div className="agent-monitoring__block">
            <div className="agent-monitoring__block-title">3. {t('Follow the link')}</div>
            <div className="agent-monitoring__block-inner">
              <p className="agent-monitoring__block-text t-body-2">{t('your telegram account connected to your whatcha account')}</p>
            </div>
          </div>
        </form>
      </div>
    </FSPopup>
  );
});

export default Messaging;
