import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ProjectsStore from '../mobx/projects';
import Icon from './Icon';
import Popup from './Popup';
import Field from './Form/Field';
import Input from './Form/Input';
import Button from './Button';
import useOnClickOutside from '../hooks/useOutsideClick';

const Tabs: React.FC = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const ref = useRef(null);
  const [tabActive, setTabActive] = useState(0);
  const [dropdown, setDropdown] = useState(false);
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [editPopupShow, setEditPopupShow] = useState(false);
  const [currentProjectSetting, setCurrentProjectSetting] = useState(0);
  const [editValue, setEditValue] = useState('');

  const editPopupOpen = () => {
    setEditValue(ProjectsStore.projects.filter((p) => p.id === currentProjectSetting)[0].name);
    setEditPopupShow(true);
  };

  useOnClickOutside(ref, () => {
    if (dropdown) setDropdown(!dropdown);
  });

  const deleteProject = () => {
    ProjectsStore.loading = true;
    ProjectsStore.deleteProject(currentProjectSetting).then(() => {
      ProjectsStore.projects = ProjectsStore.projects.filter((p) => p.id !== currentProjectSetting);
      history.push('/');
      setDeletePopupShow(false);
      ProjectsStore.loading = false;
    }).catch(() => {
      ProjectsStore.loading = false;
    });
  };

  const editProject = () => {
    ProjectsStore.loading = true;
    ProjectsStore.updateProject(
      currentProjectSetting,
      { name: editValue },
    ).then((res) => {
      setEditPopupShow(false);
      ProjectsStore.projects.filter((p) => p.id === currentProjectSetting)[0].name = res.data.project.name;
      ProjectsStore.projectTabName = res.data.project.name;
      ProjectsStore.loading = false;
    }).catch(() => {
      ProjectsStore.loading = false;
    });
  };

  const projectDropdown = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    setCurrentProjectSetting(id);
    setDropdown(e.currentTarget.className === 'dots-free' ? true : !dropdown);
  };

  const editPopupClose = () => {
    setEditValue('');
    setEditPopupShow(false);
  };

  useEffect(() => {
    ProjectsStore.changeTab(tabActive);
  }, [tabActive]);

  useEffect(() => {
    setTabActive(ProjectsStore.projectTab);
  }, [ProjectsStore.projectTab]);

  const Tab = observer(() => (
    <div className="tabs">
      <div className={`tab t-button ${tabActive === 0 ? 'active' : ''}`} onClick={() => setTabActive(0)}><span>{t('All')}</span></div>
      {ProjectsStore.projects.map((p) => (
        <div key={`projectTab#${p.id}`} className={`tab t-button ${tabActive === p.id ? 'active' : ''}`} onClick={() => setTabActive(p.id)}>
          <span>{p.name}</span>
          {tabActive === p.id && <div ref={ref} onClick={(e) => projectDropdown(e, p.id)}><Icon name="dots-tab" /></div>}
        </div>
      ))}
    </div>
  ));

  return (
    <>
      {ProjectsStore.projects.length ? <Tab /> : ''}
      <div className={`project-dropdown ${dropdown ? 'dropdown' : ''}`}>
        <div onClick={editPopupOpen}>{t('Edit')}</div>
        <div onClick={() => setDeletePopupShow(true)}>{t('Delete')}</div>
      </div>
      {editPopupShow
        && (
          <Popup onClose={editPopupClose}>
            <div className="popup-edit-project">
              <p className="t-headline-3">{t('Edit project')}</p>
              <p className="t-headline-4">{t('Title')}</p>
              <Field label={t('Enter new project title')}>
                <Input
                  value={editValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEditValue(event.target.value)}
                  onEnter={editProject}
                />
              </Field>
              <div className="buttons">
                <Button text={t('OK')} size="big" bc="main18" loading={ProjectsStore.loading} onClick={(editProject)} />
                <Button text={t('Cancel')} size="big" bc="transparent" onClick={editPopupClose} />
              </div>
            </div>
          </Popup>
        )}
      {deletePopupShow
        && (
          <Popup onClose={() => setDeletePopupShow(false)}>
            <div className="popup-delete-project">
              <p className="t-headline-3">{t('Delete project')} {ProjectsStore.projects.filter((p) => p.id === currentProjectSetting)[0]?.name}</p>
              <p className="t-body-2">{t('This action cannot be undone')}</p>
              <div className="buttons">
                <Button text={t('Delete')} size="big" bc="red" loading={ProjectsStore.loading} onClick={deleteProject} />
                <Button text={t('Cancel')} size="big" bc="transparent" onClick={() => setDeletePopupShow(false)} />
              </div>
            </div>
          </Popup>
        )}
    </>
  );
});

export default Tabs;
