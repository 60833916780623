import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Icon from '../Icon';
import CheckAuth from '../../core/checkAuth';

const AccountReady = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const createFirstMonitoring = () => {
    CheckAuth.login();
    history.push('/first-monitoring');
  };

  const closePopup = () => {
    CheckAuth.login();
  };

  return (
    <div className="auth-popup">
      <div className="auth-popup__image">
        <div className="auth-popup__icon account-ready" />
      </div>
      <div className="t-headline-2">{t('Your account is ready!')}</div>
      <div className="t-subtitle-2">
        {t('Now you can use our service and receive high-quality information about your products')}
      </div>
      <Button text={t('Create first monitoring')} size="big" bc="indigo" onClick={createFirstMonitoring} />
      <div className="popup__back" onClick={closePopup}>
        <Icon name="arrow" />
        <span className="t-button">{t('Back')}</span>
      </div>
      <div className="popup__close" onClick={closePopup}>
        <Icon name="close" />
      </div>
    </div>
  );
};

export default AccountReady;
