import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FSPopup from '../FSPopup';
import Field from '../Form/Field';
import Input from '../Form/Input';
import Button from '../Button';
import ApiClient from '../../core/apiClient';

const ProfilePassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form, setForm] = useState({
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorPassword, setErrorName] = useState<boolean>(false);
  const [errorPasswordText, setErrorNameText] = useState<string>('');

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmit = () => {
    setIsLoading(true);
    setErrorNameText('');
    setErrorName(false);
    ApiClient.changePassword(form).then((res: any) => {
      if (res?.status === 201) {
        history.push('/');
      }

      if (res.response.data.msg) {
        if (typeof res.response.data.msg === 'object') {
          Object.keys(res.response.data.msg).forEach((err: string) => {
            if (err === 'password') {
              setErrorNameText(res.response?.data.msg[err][0]);
              setErrorName(true);
            }
          });
        }
      }
      setIsLoading(false);
    }).catch(() => setIsLoading(false));
  };

  return (
    <FSPopup back={t('Return')}>
      <div className="fspopup-content">
        <div className="change-password">
          <div className="t-headline-2">{t('Create new password')}</div>
          <div className="form">
            <form>
              <Field
                label={t('Password')}
                helperText={t('Minimum 8 characters')}
                errorText={errorPasswordText}
                error={errorPassword}
                required
              >
                <Input
                  placeholder={t('Enter password')}
                  value={form.password}
                  onChange={changeHandler}
                  name="password"
                  onEnter={onSubmit}
                />
              </Field>
              <Button text={t('Change')} size="big" bc="indigo" onClick={onSubmit} loading={isLoading} />
            </form>
          </div>
        </div>
      </div>
    </FSPopup>
  );
};

export default ProfilePassword;
