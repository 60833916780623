import React from 'react';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

type HTMLContentProps = {
    content: any,
}

const HTMLContent: React.FC<HTMLContentProps> = ({ content }) => {
  const history = useHistory();

  const contentClickHandler = (e: any) => {
    const targetLink = e.target.closest('a');
    if (!targetLink) return;
    e.preventDefault();

    history.push(e.target.href);
  };

  return (
    <div onClick={contentClickHandler}>{parse(content)}</div>
  );
};

export default HTMLContent;
