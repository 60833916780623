import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from './Icon';

interface AccordionProps {
  children?: React.ReactChild,
  title?: string,
  price?: string,
  desc?: string,
  to?: number,
}

const Accordion: React.FC<AccordionProps> = observer(({
  children,
  title,
  price,
  desc,
  to,
}) => {
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    if (to) {
      setOpen(!open);
    }
  };

  return (
    <div className="accordion">
      <div className="accordion__inner">
        <div className="accordion__btn" onClick={toggleHandler}>
          <div className="accordion__btn-item accordion__btn-first">
            <div className="accordion__btn-title t-body-1">{title}</div>
            {to
              ? (
                <div className="accordion__btn-price accordion__btn-price_plus t-body-1">+{Number(price).toFixed(2)}</div>
              )
              : (
                <div className="accordion__btn-price t-body-1">-{Number(price).toFixed(2)}</div>
              )}
            {/*<div className="accordion__btn-price t-body-1"><span className="accordion__btn-status accordion__btn-status_fail">FAILED</span> {price}</div>*/}
          </div>
          {to ? (
            <div className="accordion__btn-item accordion__btn-last">
              <div className="accordion__btn-desc t-body-2">{desc}</div>
              <div className="accordion__btn-arrow">
                <Icon name="new-arrow-down" />
              </div>
            </div>
          ) : ('')}
        </div>
        <div className={open ? 'accordion__list is-open' : 'accordion__list'}>{children}</div>
      </div>
    </div>
  );
});

export default Accordion;
