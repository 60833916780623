import React, { useState, useEffect, ReactElement } from 'react';

interface InputProps {
  text?: ReactElement,
  check?: boolean,
  error?: string,
  toggleHandler?: any
}

const Checkbox = ({
  text,
  check,
  error,
  toggleHandler,
}: InputProps) => {
  const [checked, setChecked] = useState(check);

  useEffect(() => {
    setChecked(check ?? false);
  }, [check]);

  const handelClick = () => {
    setChecked(checked !== true);
    toggleHandler(checked !== true);
  };

  return (
    <div className={checked ? 'checkbox checkbox_active' : 'checkbox'} onClick={handelClick}>
      <div className="checkbox__btn">
        <div className="checkbox__icon"></div>
      </div>
      <div className="checkbox__text">{text}</div>
      <span className="checkbox__error t-caption">{error}</span>
    </div>
  );
};

export default Checkbox;
