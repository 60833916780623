import { makeAutoObservable } from 'mobx';
import ApiClient from '../core/apiClient';
import { IProject } from '../interfaces/project';

class ProjectsStore {
  projects: IProject[] = [];

  projectTab: number = 0;

  projectTabName: string = '';

  loading: boolean = false;

  lastCreatedProjectId: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  changeTab(projectId: number) {
    this.projectTab = projectId;
  }

  setProjects(projects: IProject[]) {
    this.projects = projects;
  }

  setProjectTab(projectId: number) {
    this.projectTab = projectId;
  }

  setProjectTabName(projectName: string) {
    this.projectTabName = projectName;
  }

  getListProjects() {
    ApiClient.getListProjects().then((res) => {
      this.setProjects(res?.data.projects || []);
    });
  }

  createProject(name: string) {
    ApiClient.createProject(name).then((res) => {
      this.projects.push(res.data?.project);
      this.lastCreatedProjectId = res.data?.project?.id;
    });
  }

  updateProject(id: number, data: any) {
    return ApiClient.updateProject(id, data);
  }

  reorderProject(array: number[]) {
    return ApiClient.orderProject(array);
  }

  deleteProject(id: number) {
    return ApiClient.deleteProject(id);
  }
}

export default new ProjectsStore();
