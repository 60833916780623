import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import MeStore from '../../mobx/me';

import Icon from '../Icon';
import Button from '../Button';
import Field from '../Form/Field';
import Input from '../Form/Input';
import MessagingPhone from './MessagingPhone';

const Messaging = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [codeValue, setCodeValue] = useState<string>('');
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    setTime(MeStore.isTime);
  }, [MeStore.isTime]);

  const addPhone = (value: string) => {
    MeStore.addPhone(value.replace(/[^\d]/g, ''));
  };

  const validatePhone = () => {
    MeStore.validatePhone(phoneValue.replace(/[^\d]/g, ''), Number(codeValue));
  };

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => setTime(time - 1), 1000);
      MeStore.isTime = 0;
    }
  }, [time, MeStore.isTime]);

  // Adding telegram Account
  const params = new URLSearchParams(window.location.search);
  const newTelegramId = params.get('telegram');

  const storeTelegramId = () => {
    MeStore.setTelegram(Number(newTelegramId));
    params.delete('telegram');
    history.push('/profile/messaging');
  };

  const back = () => history.goBack();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      addPhone(phoneValue);
    }
  };

  return (
    <>
      <div className="profile-back" onClick={back}>
        <Icon name="arrow" />
        <span>{t('Messaging')}</span>
      </div>
      <div className="profile-messages">
        <div className="t-headline-4">{t('SMS Check')}</div>
        <div className="t-body-2">{t('Added phone numbers')}</div>
        <div className="phone">
          {MeStore.me?.phones?.map((item) => (
            <MessagingPhone key={item?.id} phone={item?.phone} />
          ))}
        </div>
        <div className="mt24">
          <Field label={t('New phone number')}>
            <div className="input-wrapper">
              <InputMask
                name="code"
                mask="+7 999 999-99-99"
                placeholder="+7 ___ ___-__-__"
                value={phoneValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneValue(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </Field>
        </div>
        <div className="buttons-row">
          <Button text={time === 0 ? 'Отправить код' : `${t('Resend')} (${time}${t('s')})`} bc="main2" size="big" disabled={time !== 0 ?? true} loading={MeStore.loading} onClick={() => addPhone(phoneValue)} />
          <div className="t-subtitle-2">{t('If you didn’t receive SMS, try sending it again')}</div>
        </div>
        {MeStore.isTimeActive && (
          <>
            <div className="mt24">
              <Field label={t('Verification code')}>
                <Input
                  name="code"
                  placeholder="000000"
                  value={codeValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCodeValue(e.target.value)}
                  onEnter={validatePhone}
                  autocomplete={false}
                />
              </Field>
            </div>
            <div className="buttons-row">
              <Button text={`${t('profile-code')}`} bc="main2" size="big" onClick={validatePhone} loading={MeStore.codeLoading} />
            </div>
          </>
        )}
        <div className="telegram">
          <div className="t-headline-4">{t('Telegram bot')}</div>
          {(MeStore.me?.telegram_id !== undefined && MeStore.me?.telegram_id > 0) && (
            <div className="phone">
              <div className="phone-block">
                <div className="top-line">
                  <div className="name">{MeStore.me?.telegram_id}</div>
                </div>
                <div className="bottom-line">
                  <div className="default">{t('Your Account Id')}</div>
                  <div className="remove" onClick={() => { MeStore.removeTelegram(); }}>
                    <Icon name="x-circle-fill" />
                    <span>{t('Remove')}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {newTelegramId && (
            <div className="mt24">
              <Field label={t('Your new Telegram Id')}>
                <Input
                  name="telegramId"
                  placeholder="0000"
                  value={newTelegramId}
                  autocomplete={false}
                />
              </Field>
              <div className="buttons-row">
                <Button text="Save" bc="main2" size="big" onClick={storeTelegramId} />
              </div>
            </div>
          )}
          {!newTelegramId && (MeStore.me?.telegram_id === undefined || MeStore.me?.telegram_id === null || MeStore.me?.telegram_id === 0) && (
            <NavLink to="/profile/messaging/telegram" exact>
              <Button text={t('Connect Telegram Bot')} size="big" bc="main18" iconRight="chevron-right" />
            </NavLink>
          )}
        </div>
      </div>
    </>
  );
});

export default Messaging;
