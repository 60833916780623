import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Icon from './Icon';
import { IMonitor } from '../interfaces/monitor';
import Popup from './Popup';
import Button from './Button';
import MonitorsStore from '../mobx/monitors';
import MeStore from '../mobx/me';
import useOnClickOutside from '../hooks/useOutsideClick';
import getColorCode from '../scripts/getColorCode';
import getColorLatency from '../scripts/getColorLatency';
import getHM from '../scripts/getHM';
import Input from './Form/Input';
import Field from './Form/Field';
import MonitorShare from './MonitorShare';

const Monitor: React.FC<IMonitor> = observer((props) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [dropdown, setDropdown] = useState(false);
  const [sharePopupShow, setSharePopupShow] = useState(false);
  const [emailValue, setEmailValue] = useState<string>('');
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [activePopupShow, setActivePopupShow] = useState(false);

  useOnClickOutside(ref, () => {
    if (dropdown) setDropdown(!dropdown);
  });

  const addEmail = (value: string) => {
    MonitorsStore.emailUsers.push(value);
    setEmailValue('');
  };

  const shareMonitorUser = () => {
    MonitorsStore.getMonitorInvites(props.id);
  };

  const shareMonitorAddUser = () => {
    setEmailValue('');

    MonitorsStore.monitorInvitesAdd(props.id, emailValue ? [emailValue] : MonitorsStore.emailUsers);
    MonitorsStore.emailUsers = [];
  };

  const stopMonitor = () => {
    MonitorsStore.loading = true;
    MonitorsStore.stopMonitor(props.id).then((res: any) => {
      if (!res?.response?.status) {
        toast.success(t('Monitor stopped'));
        MonitorsStore.monitors.filter((m) => m.id === props.id)[0].status = 1;
      }
    }).finally(() => {
      setActivePopupShow(false);
      MonitorsStore.loading = false;
    });
  };

  const startMonitor = () => {
    MonitorsStore.loading = true;
    MonitorsStore.startMonitor(props.id).then((res: any) => {
      if (!res?.response?.status) {
        toast.success(t('Monitor started'));
        MonitorsStore.monitors.filter((m) => m.id === props.id)[0].status = 0;
      }
    }).finally(() => {
      setActivePopupShow(false);
      MonitorsStore.loading = false;
    });
  };

  const deleteMonitor = () => {
    MonitorsStore.loading = true;
    MonitorsStore.deleteMonitor(props.id).then(() => {
      MonitorsStore.monitors = MonitorsStore.monitors.filter((m) => m.id !== props.id);
      setDeletePopupShow(false);
      MonitorsStore.loading = false;
    }).catch(() => {
      MonitorsStore.loading = false;
    });
  };

  if (props.state === null || props.state === undefined) {
    return (
      <>
        <div className={`monitor ${props.status === 1 ? 'monitor--paused' : ''}  ${props.status === 2 ? 'monitor--stopped' : ''}`} ref={ref}>
          <div className="monitor__header">
            <div className="t-headline-4">{props.name}</div>
            <div className="dots" onClick={() => setDropdown((prevState) => !prevState)}>
              <Icon name="dots-three" />
            </div>
          </div>
          <div className="monitor__description">
            <div className="t-caption">
              {props.project?.name}
            </div>
            <div className="t-caption">{props.type}</div>
            {props.status === 1 && (
              <div className="t-caption monitor__sync">{t('Sync paused')}</div>
            )}
            {props.status === 2 && (
              <div className="t-caption monitor__sync">{t('Sync stopped')}</div>
            )}
          </div>
          <div className="monitor__info">
            <div>
              <div className="t-headline-4">{t('N/A')}</div>
              <div className="t-label-2">{t('Status')}</div>
            </div>
            <div>
              <div className="t-headline-4">{t('N/A')}</div>
              <div className="t-label-2">{t('Latency')}</div>
            </div>
            <div>
              <div className="t-headline-4">{t('N/A')}</div>
              <div className="t-label-2">{t('HTTP code')}</div>
            </div>
          </div>
          <div className="monitor__line">
            <div className="line__icon">
              <Icon name="warning" />
            </div>
            <div className="t-label-2">{t('Alerts')} ({t('24h')})</div>
            <div className="line__value t-body-2">{t('Clear')}</div>
          </div>
          <div className="monitor__line">
            <div className="line__icon">
              <Icon name="uptime" />
            </div>
            <div className="t-label-2">Uptime ({t('24h')})</div>
            <div className="line__value t-body-2">{t('N/A')}</div>
          </div>
          <div className={`monitor-dropdown ${dropdown ? 'dropdown' : ''}`}>
            <div onClick={() => setDeletePopupShow(true)}>{MeStore.me?.id === props.user_id ? t('Delete') : t('Unsubscribe')}</div>
            {MeStore.me?.id === props.user_id && (
              <>
                <div onClick={() => setSharePopupShow(true)}>{t('Share')}</div>
                <div onClick={() => setActivePopupShow(true)}>
                  {props.status === 0 || props.status === 2 ? t('Stop') : t('Start')}
                </div>
                <div>
                  <Link to={`/edit-monitoring/${props.id}`}>{t('Edit')}</Link>
                </div>
              </>
            )}
          </div>
          <Link to={`/monitor/${props.id}`} />
          {props.errors && (
            <div className="monitor__errors">
              <div className="list-errors">
                {props.errors.map((item: any) => (
                  <p className={`item-error item-error--status-${item.level}`}>{item.msg}</p>
                ))}
              </div>
            </div>
          )}
        </div>
        {activePopupShow
          && (
            <Popup onClose={() => setActivePopupShow(false)}>
              <div className="popup-delete-project">
                <p className="t-headline-3">{props.status === 0 || props.status === 2 ? t('Stop monitoring') : t('Start monitoring')} {props.name}</p>
                <p className="t-body-2">{props.status === 0 || props.status === 2 ? t('Are you sure you want to stop monitoring?') : t('Are you sure you want to start monitoring?')}</p>
                <div className="buttons">
                  <Button
                    text={props.status === 0 || props.status === 2 ? t('Stop') : t('Start')}
                    size="big"
                    bc="red"
                    loading={MonitorsStore.loading}
                    onClick={props.status === 0 || props.status === 2 ? stopMonitor : startMonitor}
                  />
                  <Button text={t('Cancel')} size="big" bc="transparent" onClick={() => setActivePopupShow(false)} />
                </div>
              </div>
            </Popup>
          )}
        {deletePopupShow
          && (
            <Popup onClose={() => setDeletePopupShow(false)}>
              <div className="popup-delete-project">
                <p className="t-headline-3">{t('Delete monitor')} {props.name}</p>
                <p className="t-body-2">{t('This action cannot be undone')}</p>
                <div className="buttons">
                  <Button
                    text={t('Delete')}
                    size="big"
                    bc="red"
                    loading={MonitorsStore.loading}
                    onClick={deleteMonitor}
                  />
                  <Button text={t('Cancel')} size="big" bc="transparent" onClick={() => setDeletePopupShow(false)} />
                </div>
              </div>
            </Popup>
          )}
        {sharePopupShow && (
          <Popup onClose={() => setSharePopupShow(false)}>
            <div className="popup-share-project">
              <p className="t-headline-3">{t('Share project')}</p>
              <div className="popup-share-project__input">
                {/*<div className="popup-share-project__link t-button">Copy project link</div>*/}
                <Field label={t('Enter collaborator email')}>
                  <Input
                    name="email"
                    placeholder={t('Email')}
                    value={emailValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailValue(e.target.value)}
                    onEnter={() => addEmail(emailValue)}
                    autocomplete={false}
                    emailAdd={true}
                    emailText={MonitorsStore.emailUsers}
                  />
                </Field>
              </div>
              <Button
                text={t('Invite')}
                size="big"
                bc="main18"
                loading={MonitorsStore.loading}
                onClick={shareMonitorAddUser}
              />
              <div className="popup-share-project__collaborators">
                <div className="popup-share-project__collaborators-title t-headline-4">{t('Collaborators')}</div>
                {props.id && (
                  <MonitorShare invites={MonitorsStore.invites} monitorId={props.id} />
                )}
              </div>
            </div>
          </Popup>
        )}
      </>
    );
  }

  return (
    <>
      <div className={`monitor ${props.status === 1 ? 'monitor--paused' : ''}  ${props.status === 2 ? 'monitor--stopped' : ''}`} ref={ref}>
        <div className="monitor__header">
          <div className="t-headline-4">{props.name}</div>
          <div className="dots" onClick={() => setDropdown((prevState) => !prevState)}>
            <Icon name="dots-three" />
          </div>
        </div>
        <div className="monitor__description">
          <div className="t-caption">
            {props.project?.name}
          </div>
          <div className="t-caption">{props.type}</div>
          {props.status === 1 && (
            <div className="t-caption monitor__sync">{t('Sync paused')}</div>
          )}
          {props.status === 2 && (
            <div className="t-caption monitor__sync">{t('Sync stopped')}</div>
          )}
        </div>
        <div className="monitor__info">
          <div>
            <div
              className={`t-headline-4 ${props.state?.state === 'ok' ? 'green' : (props.state?.state === 'error') && 'red'}`}
            >
              {props.state?.state === 'ok' ? t('Up') : props.state?.state === 'error' ? props.state?.code === 200 ? t('HealthError') : t('Down') : t('N/A')}
            </div>
            <div className="t-label-2">{t('Status')}</div>
          </div>
          <div>
            <div className={`t-headline-4 ${getColorLatency(props.state?.latency)}`}>
              {
                props.state?.latency >= 0 ? `${props.state?.latency}${t('ms')}` : t('N/A')
              }
            </div>
            <div className="t-label-2">{t('Latency')}</div>
          </div>
          <div>
            <div className={`t-headline-4 ${getColorCode(props.state?.code)}`}>
              {
                props.state?.code ? props.state?.code : t('N/A')
              }
            </div>
            <div className="t-label-2">{t('HTTP code')}</div>
          </div>
        </div>
        <div className="monitor__line">
          <div className="line__icon">
            <Icon name="warning" />
          </div>
          <div className="t-label-2">{t('Alerts')} ({t('24h')})</div>
          {(props.daily.alerts === 0 || props.daily.alerts - props.daily.newAlerts > 0)
            && (
              <div className={`line__value t-body-2 ${props.daily.alerts > 0 ? 'red' : 'green'}`}>
                {props.daily.alerts > 0 ? (
                  (props.daily.alerts - props.daily.newAlerts) > 0 ? (props.daily.alerts - props.daily.newAlerts) : ''
                ) : t('Clear')}
              </div>
            )}
          {props.daily.newAlerts > 0 && <div className="line__value t-body-2 alerts_new">{`${props.daily.newAlerts} ${t('monitor.new')}`}</div>}
        </div>
        <div className="monitor__line">
          <div className="line__icon">
            {props.state?.state === 'error' ? <Icon name="downtime" /> : <Icon name="uptime" />}
          </div>
          <div className="t-label-2">{props.state?.state === 'error' ? t('Downtime') : t('Uptime')} ({t('24h')})</div>
          <div className={`line__value t-body-2 ${props.state?.state === 'error' ? 'red' : 'green'}`}>
            {
              props.state?.state === 'error' ? getHM(props.daily.downtime) : `${parseInt(props.daily.uptime, 10)}%`
            }
          </div>
        </div>
        <div className={`monitor-dropdown ${dropdown ? 'dropdown' : ''}`}>
          <div onClick={() => setDeletePopupShow(true)}>{MeStore.me?.id === props.user_id ? t('Delete') : t('Unsubscribe')}</div>
          {MeStore.me?.id === props.user_id && (
            <>
              <div onClick={() => { setSharePopupShow(true); shareMonitorUser(); }}>{t('Share')}</div>
              <div onClick={() => setActivePopupShow(true)}>
                {props.status === 0 || props.status === 2 ? t('Stop') : t('Start')}
              </div>
              <div>
                <Link to={`/edit-monitoring/${props.id}`}>{t('Edit')}</Link>
              </div>
            </>
          )}
        </div>
        <Link to={`/monitor/${props.id}`} />
        {props.errors && (
          <div className="monitor__errors">
            <div className="list-errors">
              {props.errors.map((item: any) => (
                <p className={`item-error item-error--status-${item.level}`}>{item.msg}</p>
              ))}
            </div>
          </div>
        )}
      </div>
      {activePopupShow
        && (
          <Popup onClose={() => setActivePopupShow(false)}>
            <div className="popup-delete-project">
              <p className="t-headline-3">{props.status === 0 || props.status === 2 ? t('Stop monitoring') : t('Start monitoring')} {props.name}</p>
              <p className="t-body-2">{props.status === 0 || props.status === 2 ? t('Are you sure you want to stop monitoring?') : t('Are you sure you want to start monitoring?')}</p>
              <div className="buttons">
                <Button
                  text={props.status === 0 || props.status === 2 ? t('Stop') : t('Start')}
                  size="big"
                  bc="red"
                  loading={MonitorsStore.loading}
                  onClick={props.status === 0 || props.status === 2 ? stopMonitor : startMonitor}
                />
                <Button text={t('Cancel')} size="big" bc="transparent" onClick={() => setActivePopupShow(false)} />
              </div>
            </div>
          </Popup>
        )}
      {deletePopupShow
        && (
          <Popup onClose={() => setDeletePopupShow(false)}>
            <div className="popup-delete-project">
              <p className="t-headline-3">{t('Delete monitor')} {props.name}</p>
              <p className="t-body-2">{t('This action cannot be undone')}</p>
              <div className="buttons">
                <Button
                  text={t('Delete')}
                  size="big"
                  bc="red"
                  loading={MonitorsStore.loading}
                  onClick={deleteMonitor}
                />
                <Button text={t('Cancel')} size="big" bc="transparent" onClick={() => setDeletePopupShow(false)} />
              </div>
            </div>
          </Popup>
        )}
      {sharePopupShow && (
        <Popup onClose={() => setSharePopupShow(false)}>
          <div className="popup-share-project">
            <p className="t-headline-3">{t('Share project')}</p>
            <div className="popup-share-project__input">
              {/*<div className="popup-share-project__link t-button">Copy project link</div>*/}
              <Field label={t('Enter collaborator email')}>
                <Input
                  name="email"
                  placeholder={t('Email')}
                  value={emailValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailValue(e.target.value)}
                  onEnter={() => addEmail(emailValue)}
                  autocomplete={false}
                  emailAdd={true}
                  emailText={MonitorsStore.emailUsers}
                />
              </Field>
            </div>
            <Button
              text={t('Invite')}
              size="big"
              bc="main18"
              loading={MonitorsStore.loading}
              onClick={shareMonitorAddUser}
            />
            <div className="popup-share-project__collaborators">
              <div className="popup-share-project__collaborators-title t-headline-4">{t('Collaborators')}</div>
              {props.id && (
                <MonitorShare invites={MonitorsStore.invites} monitorId={props.id} />
              )}
            </div>
          </div>
        </Popup>
      )}
    </>
  );
});

export default Monitor;
