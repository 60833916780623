import React, { useEffect, useState } from 'react';
import Icon from '../Icon';
import Select from './Select';
import MonitorsStore from '../../mobx/monitors';

interface InputProps {
  name?: string,
  placeholder?: string,
  icon?: string,
  cleanable?: boolean,
  value?: string,
  onChange?: any,
  onEnter?: any,
  select?: string[],
  noHost?: boolean,
  onChangeSelect?: any,
  autocomplete?: boolean,
  emailAdd?: boolean,
  emailText?: any,
}

const Input = ({
  name, placeholder, icon, cleanable, value, onChange, onEnter, select, onChangeSelect, autocomplete, emailAdd, emailText, noHost,
}: InputProps) => {
  const isPassword: boolean = (name === 'password');
  const [inputValue, setInputValue] = useState(value);
  const [showPassword, setShowPassword] = useState(false);
  const [hostDefaultValue, setHostDefaultValue] = useState('http://');

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^(https?|ftp):\/\//;

    const hostIncludes = event.target.value.match(regex);

    if (hostIncludes && !noHost) {
      setHostDefaultValue(hostIncludes[0]);

      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(regex, '');
    }

    setInputValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (onEnter) {
      if (event.key === 'Enter') {
        onEnter(event);
      }
    }
  };

  const selectChangeHandler = (value: string) => {
    onChangeSelect(value);
  };

  const removeEmail = (value: string) => {
    MonitorsStore.emailUsers = MonitorsStore.emailUsers.filter((item) => item !== value);
  };

  const input = (
    <input
      name={name}
      className={`t-input ${icon ? 'with-icon' : ''}`}
      type={isPassword ? (showPassword ? 'text' : 'password') : 'text'}
      placeholder={placeholder}
      required
      value={inputValue}
      onChange={changeHandler}
      onKeyDown={handleKeyDown}
      autoComplete={autocomplete === false ? 'off' : 'on'}
    />
  );

  return (
    <div className={`input-wrapper ${select ? 'input-with-select' : ''} ${emailAdd ? 'add' : ''}`}>
      {emailAdd ? (
        <div className="input-wrapper__email">
          <div className="input-wrapper__email-text">
            {emailAdd && emailText.map((item : any) => (
              <div className="popup-share-project__email">
                <span>{item}</span>
                <div onClick={() => removeEmail(item)}>
                  <Icon name="small-close" />
                </div>
              </div>
            ))}
          </div>
          { input }
        </div>
      ) : input}
      {select && <Select options={select} defaultValue={hostDefaultValue} onChange={selectChangeHandler} />}
      {icon && <div className="input-wrapper__icon"><Icon name={icon} /></div>}
      {cleanable && !isPassword
        && (
          <div
            className="input-wrapper__icon-right icon_clear"
            onClick={() => setInputValue('')}
          >
            <Icon name="clear" />
          </div>
        )}
      {isPassword
        && (
          <div
            className="input-wrapper__icon-right icon_hidden"
            onClick={() => setShowPassword((prevState) => !prevState)}
          >
            <Icon name={showPassword ? 'no-eye' : 'eye'} />
          </div>
        )}
    </div>
  );
};

export default Input;
