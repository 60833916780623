import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import NamePhoto from '../NamePhoto';
import Input from '../Form/Input';
import useOnClickOutside from '../../hooks/useOutsideClick';
import CheckAuth from '../../core/checkAuth';
import ApiClient from '../../core/apiClient';
import NotificationsStore from '../../mobx/notifications';
import EventsStore from '../../mobx/events';
import MeStore from '../../mobx/me';
import Button from '../Button';
import Popup from '../Popup';

const Header = observer(() => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [popup, setPopup] = useState(false);
  const [unread, setUnread] = useState(false);

  useOnClickOutside(ref, () => {
    if (dropdown) setDropdown(!dropdown);
  });

  const handelOpenPopup = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setPopup(!popup);
  };

  const logoutClick = () => {
    setLoading(true);

    ApiClient.logout().then(() => {
      CheckAuth.logout();

      setLoading(false);
    }).catch(() => setLoading(false));
  };

  const eventClick = () => {
    if (NotificationsStore.open) {
      NotificationsStore.setClose();
    } else {
      NotificationsStore.setOpen();
    }
    setTimeout(() => {
      ApiClient.readEvents().then();
      EventsStore.getListEvents();
    }, 3500);
  };

  useEffect(() => {
    MeStore.getMe();
    EventsStore.getListEvents();
  }, []);

  useEffect(() => {
    if (EventsStore.events) {
      const unreadMessage = EventsStore.events.filter((event) => event.isNew);
      EventsStore.setEventsNew(unreadMessage.length);
      setUnread(unreadMessage.length > 0);
    } else {
      setUnread(false);
    }
  }, [EventsStore.events, EventsStore.eventsNew]);

  return (
    <header className="header">
      <div className="search">
        <Input placeholder={t('Search by monitoring title or IP')} icon="magnifier" />
      </div>
      <div
        className="notification-icon"
        onClick={eventClick}
      >
        <Icon name="bell" />
        {unread && <div className="counter">{EventsStore.eventsNew}</div>}
      </div>
      <div
        className={`user ${dropdown ? 'dropdown' : ''}`}
        onClick={() => setDropdown(!dropdown)}
        ref={ref}
      >
        <NamePhoto name={MeStore?.me?.name || MeStore.me?.email.split('@')[0] || ''} size="medium" avatar={MeStore?.me?.avatar} />
        <span className="t-button">{MeStore.me?.name || MeStore.me?.email.split('@')[0] || ''}</span>
        <Icon name="chevron" />
        <div className="user-dropdown">
          <Link to="/profile">{t('Profile')}</Link>
          <Link className={`${loading ? 'g-disabled' : ''}`} to="/" onClick={(e) => handelOpenPopup(e)}>{t('Log Out')}</Link>
        </div>
      </div>
      {popup ? (
        <Popup onClose={handelOpenPopup}>
          <div className="popup-logOut">
            <p className="t-headline-3">{t('Are you sure you want to log out of your account?')}</p>
            <div className="buttons">
              <Button
                text={t('Log Out')}
                size="big"
                bc="red"
                loading={loading}
                onClick={() => logoutClick()}
              />
              <Button text={t('Cancel')} size="big" bc="transparent" onClick={() => handelOpenPopup()} />
            </div>
          </div>
        </Popup>
      ) : null}
    </header>
  );
});

export default Header;
