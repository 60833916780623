import React from 'react';

interface useSkipLinkP {
  className?: string
  children: React.ReactElement
  skipTo: string
}

const useSkipLink: React.FC<useSkipLinkP> = ({ className, children, skipTo }) => {
  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const container: (HTMLElement | null) = document.querySelector(`.${skipTo}`);
    const menu: (HTMLElement | null) = document.querySelector('.menu-mobile');

    if (container) {
      container.scrollIntoView({ behavior: 'smooth' });

      if (menu?.classList.contains('menu-mobile--active')) {
        menu?.classList.remove('menu-mobile--active');
      }
    }
  };

  return React.cloneElement(children, { onClick, className });
};

export default useSkipLink;
