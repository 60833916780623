import React from 'react';

interface FieldProps {
  label?: string,
  disabled?: boolean,
  error?: boolean,
  required?: boolean,
  helperText?: string,
  errorText?: string,
  children?: React.ReactChild,
}

const Field: React.FC<FieldProps> = ({
  label,
  disabled,
  error,
  required,
  helperText,
  errorText,
  children,
}) => (
  <div className={`field ${disabled ? 'disabled' : ''} ${error ? 'error' : ''}`}>
    {label && <label className="t-label-2">{label}{required && <span className="required">*</span>}</label>}
    {children}
    {(helperText || error) && <div className="field-text t-caption">{error ? errorText : helperText}</div>}
  </div>
);

export default Field;
