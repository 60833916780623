import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';

import FSPopup from '../FSPopup';
import Field from '../Form/Field';
import Button from '../Button';
import apiClient from '../../core/apiClient';
import MeStore from '../../mobx/me';

const Help = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [form, setForm] = useState({
    description: '',
  });

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmit = () => {
    setIsLoading(true);
    const userId = MeStore.me?.id;
    apiClient.createTicket(form.description, userId ?? 0).then(() => {
      setIsLoading(false);
      history.push('/help/success');
    }).catch(() => setIsLoading(false));
  };

  return (
    <FSPopup back={t('Return')}>
      <div className="help-form">
        <div className="t-headline-2">{t('Help Center')}</div>
        <div className="form">
          <form>
            <Field label={t('HELP_Description')}>
              <TextArea
                placeholder={t('HELP_Placeholder')}
                value={form.description}
                onChange={changeHandler}
                name="description"
              />
            </Field>
            <Button text={t('Submit')} bc="main18" size="big" onClick={onSubmit} loading={isLoading} />
          </form>
        </div>
      </div>
    </FSPopup>
  );
};

export default Help;
