import React from 'react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';

import { IMonitor } from '../../interfaces/monitor';
import { IRates } from '../../interfaces/rates';

interface MonitoringsRowProps {
    item: IMonitor,
    rates: IRates[],
}

const MonitoringsRow:React.FC<MonitoringsRowProps> = observer(({ item, rates }) => (
  <div className="summary-item__row">
    <div className="summary-item__block-item summary-item__block-item_monitor t-subtitle-2">{item.name}</div>
    <div className="summary-item__block-item summary-item__block-item_type t-subtitle-2">{t(item.type ?? '')}</div>
    {rates.map((i) => i.type === item.type && (<div className="summary-item__block-item summary-item__block-item_month t-subtitle-2">{i.rate} ₽</div>))}
  </div>
));

export default MonitoringsRow;
