import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import NotificationsStore from '../../mobx/notifications';
import MeStore from '../../mobx/me';
import Input from '../Form/Input';
import Icon from '../Icon';

const Notifications = observer(() => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState(NotificationsStore.notifications);
  const projectsWithNotifications = [...new Set(notifications.map((n) => n.project).map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));

  useEffect(() => {
    NotificationsStore.getList();
  }, []);

  useEffect(() => {
    setNotifications(NotificationsStore.notifications);
  }, [NotificationsStore.notifications]);

  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotifications(NotificationsStore.notifications.filter((n) => n.name.toUpperCase().includes(event.target.value.toUpperCase())));
  };

  const history = useHistory();
  const back = () => history.goBack();

  return (
    <>
      <div className="profile-back" onClick={back}>
        <Icon name="arrow" />
        <span>{t('Notifications')}</span>
      </div>
      <div className="profile-notifications">
        <div className="t-headline-4">{t('Notifications')}</div>
        <div className="search">
          <Input placeholder={t('Search by monitoring title')} icon="magnifier" onChange={searchHandler} />
        </div>
        <div className="notifications">
          {[...projectsWithNotifications].map((project: { id: number, name: string }) => (
            <div className="notification-block">
              <div className="notification-block-header">
                <div className="t-inter">{project.name}</div>
                <div className={`checkboxes ${MeStore.me?.telegram_id && MeStore.me?.phones?.length !== 0 ? 'checkboxes--three' : (MeStore.me?.telegram_id || MeStore.me?.phones?.length !== 0) ? 'checkboxes--two' : ''}`}>
                  <div className="checkbox"><Icon name="email" /></div>
                  {MeStore.me?.telegram_id && (
                    <div className="checkbox"><Icon name="telegram" /></div>
                  )}
                  {MeStore.me?.phones && MeStore.me?.phones?.length !== 0 && (
                    <div className="checkbox"><Icon name="sms" /></div>
                  )}
                </div>
              </div>
              <div className="notification-monitors">
                {notifications.filter((monitor) => monitor.project.id === project.id).map((m) => (
                  <div className="notification-monitor" key={m.id}>
                    <div className="t-inter">{m.name}</div>
                    <div className={`checkbox  ${m.is_notify_email ? 'active' : ''}`} onClick={() => NotificationsStore.toggleNotify(m.id, !m.is_notify_email, 'email')}>
                      <div className="notification-checkbox">
                        <div className="circle" />
                      </div>
                    </div>
                    {MeStore.me?.telegram_id && (
                      <div className={`checkbox  ${m.is_notify_telegram ? 'active' : ''}`} onClick={() => NotificationsStore.toggleNotify(m.id, !m.is_notify_telegram, 'telegram')}>
                        <div className="notification-checkbox">
                          <div className="circle" />
                        </div>
                      </div>
                    )}
                    {MeStore.me?.phones && MeStore.me?.phones?.length !== 0 && (
                      <div className={`checkbox  ${m.is_notify_sms ? 'active' : ''}`} onClick={() => NotificationsStore.toggleNotify(m.id, !m.is_notify_sms, 'sms')}>
                        <div className="notification-checkbox">
                          <div className="circle" />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

export default Notifications;
