import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import NotificationsStore from '../../mobx/notifications';
import Tabs from './Tabs';
import DayNotification from './DayNotification';
import Icon from '../Icon';

const Notification: React.FC = observer(() => {
  const { t } = useTranslation();

  const eventClick = () => {
    NotificationsStore.open = !NotificationsStore.open;
  };

  return (
    <div className={`notification ${NotificationsStore.open ? 'show' : ''}`}>
      <div className="notification__back" onClick={eventClick}>
        <Icon name="arrow" />
      </div>
      <div className="t-headline-2">{t('Events')}</div>
      <Tabs />
      <DayNotification />
    </div>
  );
});

export default Notification;
