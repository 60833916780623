import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MonitorsStore from '../../mobx/monitors';
import MonitoringsRow from './MonitoringsRow';
import Icon from '../Icon';

const Monitorings = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    MonitorsStore.limit = 25;
    MonitorsStore.getListMonitors();
    MonitorsStore.getRates('RUR');
  }, []);

  const price = () => {
    let summary = 0;

    MonitorsStore.monitors.map((item) => MonitorsStore.rates.map((i) => {
      if (i.type === item.type) {
        summary += i.rate;
        return summary;
      }
      return summary;
    }));
    return summary;
  };

  const history = useHistory();
  const back = () => history.goBack();

  return (
    <>
      <div className="profile-back" onClick={back}>
        <Icon name="arrow" />
        <span>{t('Monitorings')}</span>
      </div>
      <div className="profile-monitorings">
        <div className="summary">
          <div className="summary__text t-headline-3">{t('profile-monitorings-title-summary')}</div>
          <div className="summary__price t-headline-2">{price()} ₽</div>
        </div>

        <div className="summary">
          <div className="summary__text t-headline-3">{t('profile-monitorings-title-block')}</div>
          <div className="summary__inner">
            <div className="summary-item">
              {/*<div className="summary-item__title t-headline-4">Google</div>*/}
              <div className="summary-item__block">
                <div className="summary-item__row">
                  <div className="summary-item__block-item summary-item__block-item_monitor t-subtitle-2">{t('Monitor')}</div>
                  <div className="summary-item__block-item summary-item__block-item_type t-subtitle-2">{t('Type')}</div>
                  <div className="summary-item__block-item summary-item__block-item_month t-subtitle-2">{t('Month cost')}</div>
                </div>
                {MonitorsStore.monitors.map((item) => (
                  <MonitoringsRow key={item.id} item={item} rates={MonitorsStore.rates} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Monitorings;
