import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Input from '../Form/Input';
import Button from '../Button';
import Icon from '../Icon';
import Field from '../Form/Field';
import ProjectsStore from '../../mobx/projects';
import MonitorsStore from '../../mobx/monitors';
import ApiClient from '../../core/apiClient';
import SelectProjects from '../Form/SelectProjects';

const FirstMonitoring = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectProject, setSelectProject] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [errorProject, setErrorProject] = useState<boolean>(false);
  const [errorProjectText, setErrorProjectText] = useState<string>('');
  const [form, setForm] = useState({
    url: '', name: '', project_id: -1, type: 'avail',
  });
  const [isLoading, setIsLoading] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const selectChangeHandler = (value: string) => {
    setForm({ ...form, project_id: ProjectsStore.projects.filter((p) => p.name === value)[0]?.id });
  };

  const createMonitor = (newForm?: { url: string, name: string, project_id: number, type: string }) => {
    ApiClient.createMonitor(newForm ? newForm : form).then((res) => {
      MonitorsStore.monitors.push(res.data.monitor);
      history.push('/');
      setIsLoading(false);
    }).catch(() => setIsLoading(false));
  };

  const createProject = () => {
    ApiClient.createProject(newProjectName).then((res) => {
      ProjectsStore.projects.push(res.data.project);
      setForm({ ...form, project_id: res?.data?.project?.id });

      const newForm = { ...form };
      newForm.project_id = res?.data?.project?.id;

      createMonitor(newForm);
    });
  };

  const onCreate = () => {
    setErrorProject(false);
    setErrorProjectText('');
    setIsLoading(true);
    if (!selectProject) {
      if (newProjectName) {
        createProject();
      } else {
        setIsLoading(false);
        setErrorProject(true);
        setErrorProjectText(t('The name project field is required'));
      }
      return;
    }

    createMonitor();
  };

  return (
    <div className="monitoring popup first-monitoring">
      <h2 className="t-headline-2">{t('Create first free monitoring')}</h2>
      <div className="form">
        <form>
          <Field label={t('Website address')} required>
            <Input
              placeholder={t('Enter website address')}
              value={form.url}
              onChange={changeHandler}
              name="url"
              onEnter={onCreate}
            />
          </Field>
          <Field label={t('Title')}>
            <Input
              placeholder={t('Enter title')}
              value={form.name}
              onChange={changeHandler}
              name="name"
              onEnter={onCreate}
            />
          </Field>
          <SelectProjects
            selectChangeHandler={selectChangeHandler}
            setNewProjectName={setNewProjectName}
            setSelectProjects={setSelectProject}
            error={errorProject}
            errorText={errorProjectText}
          />
          <Button text={t('Create')} bc="main18" size="big" onClick={onCreate} loading={isLoading} />
        </form>
      </div>
      <div className="popup__close" onClick={() => history.push('/')}>
        <Icon name="close" />
      </div>
    </div>
  );
});

export default FirstMonitoring;
