import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import NotificationsStore from '../../mobx/notifications';

interface ItemNotificationProps {
  title: string,
  description: string,
  type: string,
  time: string,
  nameMonitor: string,
  typeMonitor: string,
  knowledge_id?: number,
  countNew: number,
  last: boolean,
}

const ItemNotification: React.FC<ItemNotificationProps> = observer(({
  title,
  description,
  type,
  time,
  nameMonitor,
  typeMonitor,
  knowledge_id,
  countNew,
  last,
}) => {
  const handelClick = () => {
    NotificationsStore.setClose();
  };

  return (
    <div className={last ? 'notification__item notification__item_new' : 'notification__item'} data-count={`${countNew} new`}>
      <div className="notification__header">
        <Icon name={type === 'up' ? 'event-up' : type === 'down' ? 'event-down' : type === 'warning' ? 'event-warning' : type === 'danger' ? 'event-danger' : type === 'attention' ? 'event-attention' : type === 'critical' ? 'event-critical' : ''} />
        <div className="t-headline-4">{title}</div>
        <div className="notification__help" onClick={handelClick}>
          <Link to="/monitoring-agent">
            <Icon name="event-help" />{knowledge_id}
          </Link>
        </div>
      </div>
      <div className="notification__body">
        <div className="t-caption">{moment(time).from(moment())}</div>
        <div className="t-subtitle-2">{description}</div>
        <div className="notification__description">
          <div className="t-subtitle-2">{nameMonitor}</div>
          <div className="t-subtitle-2">{typeMonitor}</div>
          {/*<div className="t-subtitle-2">vault3.google.com</div>*/}
        </div>
      </div>
    </div>
  );
});

export default ItemNotification;
