import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import MeStore from '../../mobx/me';

interface PhoneProps {
  phone?: string
}

const MessagingPhone: React.FC<PhoneProps> = observer(({ phone }) => {
  const { t } = useTranslation();

  const deletePhone = () => {
    if (phone) MeStore.deletePhone(phone);
  };

  const pattern = /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
  const correctNumber = `+${phone}`.replace(pattern, '+7 $2 $3-$4-$5');

  return (
    <div className="phone-block">
      <div className="top-line">
        <div className="name">{correctNumber}</div>
      </div>
      <div className="bottom-line">
        <div className="default"></div>
        {/*{t('Default')}*/}
        {/*<div className="use-as-default">{t('Use as default')}</div>*/}
        <div className="remove" onClick={deletePhone}>
          <Icon name="x-circle-fill" />
          <span>{t('Remove')}</span>
        </div>
      </div>
    </div>
  );
});

export default MessagingPhone;
