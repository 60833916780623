import React from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, useLocation } from 'react-router-dom';

import appStore from 'mobx/app';

const AfterSignIn: React.FC = observer(() => {
  const location = useLocation();
  appStore.setRedirectTo(location.pathname + location.search);

  return <Redirect to="/sign-in" />;
});

export default AfterSignIn;
