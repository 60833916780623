import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import useOnClickOutside from '../../hooks/useOutsideClick';
import Icon from '../Icon';

interface SelectProps {
  options: Array<string | number>,
  onChange?: any,
  defaultValue?: string,
}

const Select: React.FC<SelectProps> = observer(({ options, onChange, defaultValue }) => {
  const ref = useRef(null);
  const [dropdown, setDropdown] = useState(false);
  const [value, setValue] = useState(defaultValue || options[0]);

  useOnClickOutside(ref, () => {
    if (dropdown) setDropdown(!dropdown);
  });

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div
      className={`select-wrapper ${dropdown ? 'dropdown' : ''}`}
      onClick={() => setDropdown(!dropdown)}
      ref={ref}
    >
      <div className="select t-input">{value}</div>
      <div className="icon-arrow">
        <Icon name="chevron" />
      </div>
      <div className="options">
        {options.map((option, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={key} className="option t-inter" onClick={() => setValue(options[key])}>{option}</div>
        ))}
      </div>
    </div>
  );
});

export default Select;
