import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DatePicker } from 'antd';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import moment from 'moment';
import Icon from '../Icon';
import Button from '../Button';
import MeStore from '../../mobx/me';
import ApiClient from '../../core/apiClient';
import Checkbox from '../Form/Checkbox';
import HTMLContent from '../../utils/HTMLContent';
import { interpolate } from '../../utils/localization';
import { IPayments } from '../../interfaces/payments';
import { IPaymentMethod } from '../../interfaces/paymentMethod';
import Transaction from './Transaction';
import RobokassaImg from '../../assets/images/robokassa.svg';

const Billing = observer(() => {
  useEffect(() => {
    MeStore.getMe();
    MeStore.getPayment(moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD'));
  }, []);
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [transaction, setTransaction] = useState<IPayments[]>(MeStore.payment);
  const [errorCheckbox, setErrorCheckbox] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { RangePicker } = DatePicker;

  let newDate: Array<string> = [];

  const handleCalendarChange = (value: any) => {
    value.map((date: any) => newDate.push(moment(date).format('YYYY-MM-DD')));
    MeStore.getPayment(newDate[0], newDate[1]);
    newDate = [];
  };

  useEffect(() => {
    setTransaction(MeStore.payment);
  }, [MeStore.payment]);

  const agreementStr = interpolate(t('Add payment of'), {
    link: '<a href="https://whatcha.me/public-offer" target="_blank" class="invite-project__link">',
    end_link: '</a>',
  });

  const removePaymentMethod = (methodId: number) => {
    setIsLoading(true);
    ApiClient.removePaymentMethod(methodId).then(() => {
      MeStore.removePaymentMethod(methodId);
      setIsLoading(false);
    }).catch(() => setIsLoading(false));
  };

  const initPayment = () => {
    if (active) {
      setErrorCheckbox('');
      setIsLoading(true);
      ApiClient.initPayment().then((res) => {
        setIsLoading(false);
        if (res?.data.redirectUrl) {
          window.location = res?.data.redirectUrl;
        }
      }).catch(() => setIsLoading(false));
    } else {
      setErrorCheckbox(t('error-checkbox'));
    }
  };

  const toggleHandler = (value: boolean) => {
    setActive(value);
  };

  let lastDate = '';
  const isToday = moment().toISOString();

  const history = useHistory();
  const back = () => history.goBack();

  return (
    <>
      <div className="profile-back" onClick={back}>
        <Icon name="arrow" />
        <span>{t('Billing')}</span>
      </div>
      <div className="profile-billing">
        <div className="summary">
          <div className="summary__text t-headline-3">{t('Remaining account credits')}</div>
          <div className="summary__price t-headline-2">{Number(MeStore?.me?.balance).toFixed(2)}</div>
        </div>

        <div className="summary">
          <div className="summary__text t-headline-3">{t('Payment methods')}</div>
          <div className="payment-methods">
            {MeStore?.me?.payment && MeStore.me.payment.map((method: IPaymentMethod) => (
              <div className="method-block">
                <div className="top-line">
                  <div className="name">{t(method.type)}</div>
                  <img src={RobokassaImg} alt="Robokassa" />
                </div>
                <div className="card-number t-body-2">{moment(method.created_at).format('DD.MM.YYYY HH:mm:ss')}</div>
                <div className="bottom-line">
                  {/* <div className="use-as-default">{t('Use as default')}</div> */}
                  <div className={`payment-method-status payment-method-${(method.status ? 'active' : 'inactive')}`}>{method.status ? t('active') : t('inactive') }</div>
                  <div className="remove" onClick={() => { removePaymentMethod(method.id); }}>
                    <Icon name="x-circle-fill" />
                    <span>{t('Remove')}</span>
                  </div>
                </div>
              </div>
            ))}
            <Button text={t('Add payment method')} size="big" bc="main18" iconRight="chevron-right" onClick={initPayment} loading={isLoading} disabled={isLoading} />
            <Checkbox text={<HTMLContent content={agreementStr} />} check={false} toggleHandler={toggleHandler} error={errorCheckbox} />
          </div>
        </div>

        <div className="summary">
          <div className="summary__text">
            <p className="t-headline-3">{t('transactions')}</p>
            <div className="calendar">
              <RangePicker
                locale={locale}
                placeholder={['', '']}
                onChange={handleCalendarChange}
                allowClear={false}
              />
              <p className="calendar-month t-button">Last month</p>
              <div className="calendar-btn">
                <Icon name="calendar" />
              </div>
            </div>
          </div>

          {transaction.map((item) => {
            const date = item.date.substring(0, 10);
            const renderDate = date !== lastDate;

            lastDate = date;

            return (
              <>
                <div key={`days-${date}-${item.id}`}>
                  {renderDate && (<div className="summary-item__title t-headline-4">{moment(isToday).isSame(item.date, 'day') ? `${t('Today')}` : moment(item.date).format('DD MMMM')}</div>)}
                </div>
                <Transaction {...item} />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
});

export default Billing;
