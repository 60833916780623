import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FSPopup from '../FSPopup';
import Field from '../Form/Field';
import Input from '../Form/Input';
import SelectProjects from '../Form/SelectProjects';
import Button from '../Button';
import appStore from '../../mobx/app';
import ProjectsStore from '../../mobx/projects';
import ApiClient from '../../core/apiClient';
import MonitorsStore from '../../mobx/monitors';
import Icon from '../Icon';

const SelectMonitoring = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [newProjectName, setNewProjectName] = useState('');
  const [host, setHost] = useState('http');
  const [form, setForm] = useState({
    url: '', name: '', project_id: -1, type: 'avail',
  });
  const [selectProject, setSelectProject] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorName, setErrorName] = useState<boolean>(false);
  const [errorProject, setErrorProject] = useState<boolean>(false);
  const [errorURL, setErrorURL] = useState<boolean>(false);
  const [errorNameText, setErrorNameText] = useState<string>('');
  const [errorProjectText, setErrorProjectText] = useState<string>('');
  const [errorURLText, setErrorURLText] = useState<string>('');

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const selectChangeHandler = (value: string) => {
    setForm({ ...form, project_id: ProjectsStore.projects.filter((p) => p.name === value)[0]?.id });
  };

  const createMonitor = (newForm?: { url: string, name: string, project_id: number, type: string }) => {
    ApiClient.createMonitor(newForm ? newForm : { ...form, url: host + form.url }).then((res: any) => {
      if (res?.data) {
        MonitorsStore.reachMonitors([res.data.monitor]);
        history.push(`/monitor/${res?.data?.monitor?.id}`);
        appStore.setPopupClosed();
      }

      if (res.response.data.msg) {
        if (typeof res.response.data.msg === 'object') {
          Object.keys(res.response.data.msg).forEach((err: string) => {
            if (err === 'name') {
              setErrorNameText(res.response?.data.msg[err][0]);
              setErrorName(true);
            }
          });
        }
      }

      setIsLoading(false);
    }).catch(() => setIsLoading(false));
  };

  const createProject = () => {
    ApiClient.createProject(newProjectName).then((res) => {
      ProjectsStore.projects.push(res.data.project);
      setForm({ ...form, project_id: res?.data?.project?.id });

      const newForm = { ...form, url: host + form.url };
      newForm.project_id = res?.data?.project?.id;

      createMonitor(newForm);
    });
  };

  const onCreate = () => {
    setErrorURL(false);
    setErrorURLText('');
    setErrorNameText('');
    setErrorName(false);
    setErrorProject(false);
    setErrorProjectText('');
    setIsLoading(true);

    if (!selectProject) {
      if (newProjectName) {
        createProject();
      } else {
        setIsLoading(false);
        setErrorProject(true);
        setErrorProjectText(t('The name project field is required'));
      }
      return;
    }

    if (form.url) {
      createMonitor();
    } else {
      setIsLoading(false);
      setErrorURL(true);
      setErrorURLText(t('The url field is required'));
    }
  };

  const changeHostsHandler = (value: string) => {
    setHost(value);
  };

  return (
    <FSPopup back={t('Back')}>
      <div className="select-monitoring">
        <div className="t-headline-2">{t('Create monitoring')}</div>
        <div className="t-headline-3">{t('Simple monitoring')}</div>
        <div className="form">
          <form>
            <Field label={t('Website address')} error={errorURL} errorText={errorURLText} required>
              <Input
                placeholder={t('Enter website address')}
                value={form.url}
                onChange={changeHandler}
                name="url"
                onEnter={onCreate}
                select={['http://', 'https://']}
                onChangeSelect={changeHostsHandler}
              />
            </Field>
            <Field label={t('Title')} error={errorName} errorText={errorNameText}>
              <Input
                placeholder={t('Enter title')}
                value={form.name}
                onChange={changeHandler}
                name="name"
                onEnter={onCreate}
              />
            </Field>
            <SelectProjects
              selectChangeHandler={selectChangeHandler}
              setNewProjectName={setNewProjectName}
              setSelectProjects={setSelectProject}
              error={errorProject}
              errorText={errorProjectText}
            />
            <Button text={t('Create')} bc="main18" size="big" onClick={onCreate} loading={isLoading} />
          </form>
        </div>
        <div className="t-headline-3">{t('Or more specific monitorings')}</div>
        <Link to="/monitoring/create/graphql">
          <div className="other-monitoring">
            <div className="other-monitoring__icon1">
              <Icon name="graphql" />
            </div>
            <div className="other-monitoring__info">
              <div className="title t-headline-4">{t('GRAPH_TITLE')}</div>
              <div className="description t-body-2">
                {t('GRAPH_DESCRIPTION')}
              </div>
            </div>
            <div className="other-monitoring__icon2">
              <Icon name="arrow" />
            </div>
          </div>
        </Link>
        <Link to="/monitoring/create/elastic">
          <div className="other-monitoring">
            <div className="other-monitoring__icon1">
              <Icon name="elastic" />
            </div>
            <div className="other-monitoring__info">
              <div className="title t-headline-4">{t('ELASTIC_TITLE')}</div>
              <div className="description t-body-2">
                {t('ELASTIC_DESCRIPTION')}
              </div>
            </div>
            <div className="other-monitoring__icon2">
              <Icon name="arrow" />
            </div>
          </div>
        </Link>
        <Link to="/monitoring/create/health-check">
          <div className="other-monitoring">
            <div className="other-monitoring__icon1">
              <Icon name="wrench" />
            </div>
            <div className="other-monitoring__info">
              <div className="title t-headline-4">{t('CUSTOM_TITLE')}</div>
              <div className="description t-body-2">
                {t('CUSTOM_DESCRIPTION')}
              </div>
            </div>
            <div className="other-monitoring__icon2">
              <Icon name="arrow" />
            </div>
          </div>
        </Link>
      </div>
    </FSPopup>
  );
};

export default SelectMonitoring;
