import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Tutorial from './Tutorial';
import Notify from './Notify';
import Information from './Information';
import Welcome from './Welcome';
import Price from './Price';

const Main: React.FC = () => (
  <div className="main">
    <Header />
    <div className="main-content">
      <Welcome />
      <Tutorial />
      <Price />
      <Notify />
      {/*<App />*/}
      <Information />
    </div>
    <Footer />
  </div>
);

export default Main;
