import React from 'react';
import Icon from './Icon';

interface AlertProps {
  type: string;
  title: string;
  description?: string;
  size?: string;
}

const Alert:React.FC<AlertProps> = ({
  type, title, description, size,
}) => (
  <div className={`alert alert_${type} alert_${size ? size : 'default'}`}>
    <div className="alert__icon">
      {type === 'success' && <Icon name="success" />}
      {type === 'success-default' && <Icon name="success" />}
      {type === 'warning' && (size === 'small' ? <Icon name="warning-small" /> : <Icon name="warning-big" />)}
      {type === 'good' && <Icon name="check-circle-big" />}
    </div>
    <div className="alert__text">
      <div className="t-headline-3">{title}</div>
      {description && <div className="t-body-2">{description}</div>}
    </div>
  </div>
);

export default Alert;
