import NotificationsStore from '../mobx/notifications';

export const handelOutsideNotification = (event: any) => {
  const box = document.querySelector('.notification');
  const boxIcon = document.querySelector('.notification-icon');

  if (box && boxIcon) {
    if (!box.contains(event.target) && !boxIcon.contains(event.target)) {
      NotificationsStore.setClose();
    }
  }
};
