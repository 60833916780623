import React from 'react';
import Icon from './Icon';

interface ButtonProps {
  text?: string,
  bc?: string,
  size?: string,
  iconLeft?: string,
  iconRight?: string,
  iconCenter?: string,
  disabled?: boolean,
  loading?: boolean,
  onClick?: () => void,
}

const Button: React.FC<ButtonProps> = ({
  text, bc, size, iconLeft, iconRight, iconCenter, disabled, loading, onClick,
}) => (
  <div
    className={`button ${bc ? `button_bc-${bc}` : ''} ${size ? `button_size-${size}` : ''} t-button ${disabled ? 'disabled' : ''} ${loading ? 'loading' : ''}`}
    onClick={onClick}
  >
    {iconLeft && <div className="icon-left"><Icon name={iconLeft} /></div>}
    <span>{text}</span>
    {iconRight && <div className="icon-right"><Icon name={iconRight} /></div>}
    {iconCenter && <div className="icon-center"><Icon name={iconCenter} /></div>}
    {loading && <div className="button__loader"><Icon name="ellipse" /></div> }
  </div>
);

export default Button;
