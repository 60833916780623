import { makeAutoObservable } from 'mobx';
import { IEvents } from '../interfaces/event';
import ApiClient from '../core/apiClient';

class EventsStore {
  events: IEvents[] = [];

  eventsNew: number = 0;

  eventTab: string = 'all';

  constructor() {
    makeAutoObservable(this);
  }

  getListEvents() {
    ApiClient.getListEvents().then((res) => {
      this.setEvents(res?.data?.events || []);
    });
  }

  setEventsNew(count: number) {
    this.eventsNew = count;
  }

  setEventTab(name: string) {
    this.eventTab = name;
  }

  setEvents(events: IEvents[]) {
    this.events = events;
  }
}

export default new EventsStore();
