import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../Button';
import Input from '../Form/Input';
import Field from '../Form/Field';
import MeStore from '../../mobx/me';
import Icon from '../Icon';

const MyProfile = observer(() => {
  const { t } = useTranslation();
  const [nameValue, setNameValue] = useState<null | string>(null);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameValue(event.target.value);
  };

  const fileLoad = (event: React.ChangeEvent<HTMLInputElement>) => {
    MeStore.loadFile(event.target.files);
  };

  const changeName = () => {
    MeStore.updateMe({ name: nameValue }, 'name');
  };

  const deleteAvatar = () => {
    MeStore.updateMe({ avatar: null }, 'deleteAvatar');
  };

  const history = useHistory();
  const back = () => history.goBack();

  return (
    <>
      <div className="profile-back" onClick={back}>
        <Icon name="arrow" />
        <span>{t('Profile')}</span>
      </div>
      <div className="t-headline-4">{t('Your profile')}</div>
      <div className="t-input mt24 mb16">{t('Avatar')}</div>
      <div className="buttons-row">
        <div className="upload-file">
          <label htmlFor="file-upload" className="custom-file-upload">
            <Button text={t('Change avatar')} bc="main18" size="big" loading={MeStore.isLoading === 'avatarUpload'} />
          </label>
          <input id="file-upload" type="file" onChange={fileLoad} />
        </div>
        <Button text={t('Delete avatar')} bc="main2" size="big" loading={MeStore.isLoading === 'deleteAvatar'} onClick={deleteAvatar} />
      </div>
      <div className="mt24 mb16">
        <Field label={t('Name')}>
          <Input
            name="name"
            placeholder={t('Enter name')}
            value={nameValue || MeStore?.me?.name}
            onChange={changeHandler}
            onEnter={changeName}
          />
        </Field>
      </div>
      <Button text={t('Change name')} bc="main18" size="big" loading={MeStore.isLoading === 'name'} onClick={changeName} />
    </>
  );
});

export default MyProfile;
