import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import translationPricesEN from './locales/en/prices.json';
import translationPricesRU from './locales/ru/prices.json';

const fallbackLng = ['ru'];
const availableLanguages = ['en', 'ru'];

const resources = {
  en: {
    translation: { ...translationEN, ...translationPricesEN },
  },
  ru: {
    translation: { ...translationRU, ...translationPricesRU },
  },
};

// @ts-ignore
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
