import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Field from '../Form/Field';
import Input from '../Form/Input';
import MeStore from '../../mobx/me';
import AppStore from '../../mobx/app';
import Select from '../Form/Select';
import Icon from '../Icon';
import ApiClient from '../../core/apiClient';

const Account = observer(() => {
  const { t } = useTranslation();
  const [emailValue, setEmailValue] = useState<null | string>(null);
  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorEmailText, setErrorEmailText] = useState<string>('');
  const history = useHistory();

  const updateMe = (field: string, value: any) => {
    setErrorEmailText('');
    setErrorEmail(false);
    MeStore.isLoading = 'email';
    ApiClient.updateMe({ [field]: value }).then((res: any) => {
      if (res.data?.user) {
        MeStore.me = { ...MeStore.me, ...res.data?.user };
        toast.success('Successfully updated');
      }

      if (res.response.data.msg) {
        if (typeof res.response.data.msg === 'object') {
          Object.keys(res.response.data.msg).forEach((err: string) => {
            if (err === 'email') {
              setErrorEmailText(res.response?.data.msg[err][0]);
              setErrorEmail(true);
            }
          });
        } else {
          setErrorEmailText(res.response?.data.msg);
          setErrorEmail(true);
        }
      }
      MeStore.isLoading = false;
    }).catch(() => {
      MeStore.isLoading = false;
    });
  };

  const changePassword = () => {
    MeStore.updateMe({
      old_password: oldPassword,
      password: newPassword,
    }, 'password');
  };

  const selectChangeHandler = (value: string) => {
    const language = value === 'English' ? 'en' : value === 'Русский' ? 'ru' : '';
    if (MeStore.me && language !== MeStore.me?.language) {
      MeStore.updateMe({ language }, 'language');
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage(language);
    }
  };

  const selectChangeHandlerTheme = (theme: string) => {
    if (theme === 'light') {
      AppStore.setLight();
    } else {
      AppStore.setDark();
    }

    if (MeStore.me && theme !== MeStore.me?.theme) {
      MeStore.updateMe({ theme }, 'theme');
    }
  };

  const back = () => history.goBack();

  return (
    <>
      <div className="profile-back" onClick={back}>
        <Icon name="arrow" />
        <span>{t('Account')}</span>
      </div>
      <div className="profile-account">
        <div className="account-email">
          <div className="t-headline-4">{t('Email')}</div>
          <div className="t-subtitle-2">
            {t('Update your email below. There will be a new verification email sent that you will need to use to verify this new email.')}
          </div>
          <div className="mt24">
            <Field label={t('Email')} error={errorEmail} errorText={errorEmailText}>
              <Input
                name="email"
                placeholder={t('Enter email')}
                value={emailValue || MeStore?.me?.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailValue(e.target.value)}
                onEnter={() => updateMe('email', emailValue)}
                autocomplete={false}
              />
            </Field>
          </div>
          <Button text={t('Change email')} bc="main18" size="big" loading={MeStore.isLoading === 'email'} onClick={() => updateMe('email', emailValue)} />
        </div>
        <div className="account-password">
          <div className="t-headline-4">{t('Password')}</div>
          <div className="mt24">
            <Field label={t('Old password')}>
              <Input
                name="password"
                placeholder={t('Enter old password')}
                value={oldPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOldPassword(e.target.value)}
                autocomplete={false}
              />
            </Field>
          </div>
          <div className="mt24">
            <Field label={t('New password')}>
              <Input
                name="password"
                placeholder={t('Enter new password')}
                value={newPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
                onEnter={changePassword}
                autocomplete={false}
              />
            </Field>
          </div>
          <Button text={t('Change password')} bc="main18" size="big" onClick={changePassword} />
        </div>
        <div className="account-appearance">
          <div className="t-headline-4">{t('Appearance')}</div>
          <div className="mt24">
            <Field label={t('Language')}>
              <Select options={['English', 'Русский']} onChange={selectChangeHandler} defaultValue={i18n.language === 'en' ? 'English' : i18n.language === 'ru' ? 'Русский' : ''} />
            </Field>
          </div>
        </div>
        <div className="account-appearance">
          <div className="t-headline-4">{t('Theme')}</div>
          <div className="mt24">
            <Field label={t('Color')}>
              <Select options={['light', 'dark']} onChange={selectChangeHandlerTheme} defaultValue={MeStore.me?.theme ?? AppStore.theme} />
            </Field>
          </div>
        </div>
      </div>
    </>
  );
});

export default Account;
