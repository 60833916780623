import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';

const CheckEmail = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="auth-popup">
      <div className="auth-popup__image">
        <div className="auth-popup__icon confirm-email" />
      </div>
      <div className="t-headline-2">{t('Check your email address')}</div>
      <div className="t-subtitle-2">
        {t('We have sent an email to reset your password. This link in the email will expire in 24 hours.')}
      </div>
      <div className="popup__back" onClick={history.goBack}>
        <Icon name="arrow" />
        <span className="t-button">{t('Back')}</span>
      </div>
      <div className="popup__close" onClick={() => history.push('/')}>
        <Icon name="close" />
      </div>
    </div>
  );
};

export default CheckEmail;
