import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import appStore from 'mobx/app';
import Icon from './Icon';

interface FSPopupProps {
  children?: React.ReactChild,
  back?: string,
  close?: string,
}

const FSPopup: React.FC<FSPopupProps> = observer(({
  children,
  back,
  close,
}) => {
  const history = useHistory();
  const ref = useRef(null);

  const closePopup = () => {
    appStore.setPopupClosed();
    return close ? history.push(close) : history.goBack();
  };

  appStore.setPopupOpened();

  return (
    <div className="fspopup">
      <div className="fspopup-inner" ref={ref}>
        {children}
      </div>
      {back && (
      <div className="fspopup__back" onClick={closePopup}>
        <Icon name="arrow" />
        <span className="t-button">{back}</span>
      </div>
      )}
      <div className="fspopup__close" onClick={closePopup}>
        <Icon name="close" />
      </div>
    </div>
  );
});

export default FSPopup;
