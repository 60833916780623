import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from './Icon';
import useOnClickOutside from '../hooks/useOutsideClick';

interface PopupProps {
  onClose?: any,
  children?: React.ReactChild,
}

const Popup: React.FC<PopupProps> = observer(({
  onClose,
  children,
}) => {
  const ref = useRef(null);

  useOnClickOutside(ref, (e) => {
    onClose(e);
  });

  return (
    <div className="popup">
      <div className="popup-inner" ref={ref}>
        {children}
        <div className="popup__close" onClick={(e) => onClose(e)}>
          <Icon name="close" />
        </div>
      </div>
    </div>
  );
});

export default Popup;
