import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  XAxis,
  CartesianGrid,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
} from 'recharts';
import { t } from 'i18next';

import ApiClient from '../../core/apiClient';
import Icon from '../Icon';
import headerLeft from '../../assets/images/header-left.png';
import headerRight from '../../assets/images/header-right.png';

const StatusPage = () => {
  const [monitorsCount, setMonitorsCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [checksByDays, setChecksByDays] = useState([]);
  const [checksByDaysCount, setChecksByDaysCount] = useState(0);
  const [checksByHours, setChecksByHours] = useState([]);
  const [checksByHoursCount, setChecksByHoursCount] = useState(0);
  const [checksByMinute, setChecksByMinute] = useState([]);
  const [checksByMinuteCount, setChecksByMinuteCount] = useState(0);
  const [emailByHours, setEmailByHours] = useState([]);
  const [emailByHoursCount, setEmailByHoursCount] = useState(0);
  const [smsByHours, setSmsByHours] = useState([]);
  const [smsByHoursCount, setSmsByHoursCount] = useState(0);
  const [telegramByHours, setTelegramByHours] = useState([]);
  const [telegramByHoursCount, setTelegramByHoursCount] = useState(0);

  useEffect(() => {
    ApiClient.getStatus().then((res) => {
      setMonitorsCount(res.data.monitors);
      setUsersCount(res.data.users);
      setChecksByDays(res.data.checksByDays);
      setChecksByHours(res.data.checksByHour);
      setChecksByMinute(res.data.checksByMinute);
      setEmailByHours(res.data.emailByHour);
      setSmsByHours(res.data.smsByHour);
      setTelegramByHours(res.data.telegramByHour);
    });
  }, []);

  const setCount = (array: never[], setMethod: Dispatch<SetStateAction<number>>) => {
    let sum = 0;

    array.map((item: any) => {
      sum += item?.count;

      return sum;
    });

    setMethod(sum);
  };

  useEffect(() => {
    setCount(checksByMinute, setChecksByMinuteCount);
  }, [checksByMinute]);

  useEffect(() => {
    setCount(checksByHours, setChecksByHoursCount);
  }, [checksByHours]);

  useEffect(() => {
    setCount(checksByDays, setChecksByDaysCount);
  }, [checksByHours]);

  useEffect(() => {
    setCount(emailByHours, setEmailByHoursCount);
  }, [emailByHours]);

  useEffect(() => {
    setCount(smsByHours, setSmsByHoursCount);
  }, [smsByHours]);

  useEffect(() => {
    setCount(telegramByHours, setTelegramByHoursCount);
  }, [telegramByHours]);

  return (
    <div className="status">
      <div className="status__container">
        <div className="status__header">
          <img className="status__header-left" src={headerLeft} alt="header-left" />
          <div className="status__header-title">Whatcha</div>
          <img className="status__header-right" src={headerRight} alt="header-right" />
        </div>
        {/* success or error*/}
        {/*<div className="status-alert success">*/}
        {/*  <div className="status-alert__title t-headline-3">All systems are operational</div>*/}
        {/*</div>*/}
        {/*<div className="status-alert error">*/}
        {/*  <div className="status-alert__title t-headline-3">Some system has errors</div>*/}
        {/*  <ul className="status-alert__list">*/}
        {/*    <li className="status-alert__list-item t-body-2">Website downtime: 18 hrs 15 min</li>*/}
        {/*    <li className="status-alert__list-item t-body-2">API downtime: 18 hrs 14 min</li>*/}
        {/*  </ul>*/}
        {/*</div>*/}
        <div className="status-info">
          <div className="status-info__header">
            <div className="status-info__title t-headline-4">
              Stats
              <span className="t-caption">Now</span>
            </div>
          </div>
          <div className="status-info__wrapper">
            <div className="status-info__item">
              <div className="status-info__icon">
                <Icon name="chart-line" />
              </div>
              <div className="status-info__number t-headline-4">{monitorsCount}</div>
              <div className="status-info__desc t-label-2">{t('Monitors Count')}</div>
            </div>
            <div className="status-info__item">
              <div className="status-info__icon">
                <Icon name="status-users" />
              </div>
              <div className="status-info__number t-headline-4">{usersCount}</div>
              <div className="status-info__desc t-label-2">{t('Users Count')}</div>
            </div>
          </div>
        </div>
        <div className="status-info">
          <div className="status-info__header">
            <div className="status-info__title t-headline-4">
              Checks
              <span className="t-caption">in last hour</span>
            </div>
            <div className="status-info__check">
              {checksByMinuteCount} checks
            </div>
          </div>
          <div className="status-info__wrapper">
            <AreaChart
              width={800}
              height={250}
              data={checksByMinute}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="to_char" />
              <YAxis />
              <Tooltip />
              <Area type="linear" dataKey="count" stroke="#2F54EB" fill="rgba(47, 84, 235, 0.2)" />
            </AreaChart>
          </div>
        </div>
        <div className="status-info">
          <div className="status-info__header">
            <div className="status-info__title t-headline-4">
              Checks
              <span className="t-caption">in last day</span>
            </div>
            <div className="status-info__check">
              {checksByHoursCount} checks
            </div>
          </div>
          <div className="status-info__wrapper">
            <AreaChart
              width={800}
              height={250}
              data={checksByHours}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="to_char" />
              <YAxis />
              <Tooltip />
              <Area type="linear" dataKey="count" stroke="#2F54EB" fill="rgba(47, 84, 235, 0.2)" />
            </AreaChart>
          </div>
        </div>
        <div className="status-info">
          <div className="status-info__header">
            <div className="status-info__title t-headline-4">
              Checks
              <span className="t-caption">in last week month</span>
            </div>
            <div className="status-info__check">
              {checksByDaysCount} checks
            </div>
          </div>
          <div className="status-info__wrapper">
            <AreaChart
              width={800}
              height={250}
              data={checksByDays}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Area type="linear" dataKey="count" stroke="#2F54EB" fill="rgba(47, 84, 235, 0.2)" />
            </AreaChart>
          </div>
        </div>

        <div className="status-info">
          <div className="status-info__header">
            <div className="status-info__title t-headline-4">
              Email
              <span className="t-caption">in last day</span>
            </div>
            <div className="status-info__check">
              {emailByHoursCount} checks
            </div>
          </div>
          <div className="status-info__wrapper">
            <AreaChart
              width={800}
              height={250}
              data={emailByHours}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="to_char" />
              <YAxis />
              <Tooltip />
              <Area type="linear" dataKey="count" stroke="#2F54EB" fill="rgba(47, 84, 235, 0.2)" />
            </AreaChart>
          </div>
        </div>

        <div className="status-info">
          <div className="status-info__header">
            <div className="status-info__title t-headline-4">
              SMS
              <span className="t-caption">in last day</span>
            </div>
            <div className="status-info__check">
              {smsByHoursCount} checks
            </div>
          </div>
          <div className="status-info__wrapper">
            <AreaChart
              width={800}
              height={250}
              data={smsByHours}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="to_char" />
              <YAxis />
              <Tooltip />
              <Area type="linear" dataKey="count" stroke="#2F54EB" fill="rgba(47, 84, 235, 0.2)" />
            </AreaChart>
          </div>
        </div>

        <div className="status-info">
          <div className="status-info__header">
            <div className="status-info__title t-headline-4">
              Telegram
              <span className="t-caption">in last day</span>
            </div>
            <div className="status-info__check">
              {telegramByHoursCount} checks
            </div>
          </div>
          <div className="status-info__wrapper">
            <AreaChart
              width={800}
              height={250}
              data={telegramByHours}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="to_char" />
              <YAxis />
              <Tooltip />
              <Area type="linear" dataKey="count" stroke="#2F54EB" fill="rgba(47, 84, 235, 0.2)" />
            </AreaChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusPage;
