import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import MonitorsStore from '../../mobx/monitors';
import Field from '../Form/Field';
import Select from '../Form/Select';
import ProjectsStore from '../../mobx/projects';
import Input from '../Form/Input';
import Icon from '../Icon';
import Alert from '../Alert';
import ApiClient from '../../core/apiClient';

const GraphQLCreate = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [selectProject, setSelectProject] = useState(true);
  const [newProjectName, setNewProjectName] = useState('');
  const [host, setHost] = useState('http://');
  const [form, setForm] = useState({
    url: '', name: '', project_id: -1, type: 'graphQL',
  });

  const [testConnectionAlert, setTestConnectionAlert] = useState('');
  const [testConnectionLoading, setTestConnectionLoading] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'url') setTestConnectionAlert('');
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const selectChangeHandler = (value: string) => {
    setForm({ ...form, project_id: ProjectsStore.projects.filter((p) => p.name === value)[0]?.id });
  };

  const testConnection = () => {
    setTestConnectionLoading(true);
    ApiClient.testConnection('graphQL', host + form.url).then((res) => {
      if (res.data?.result === true) {
        setTestConnectionAlert(t('Server is connected!'));
      } else if (res.data?.state?.error?.length > 0) {
        setTestConnectionAlert(res.data?.state?.error);
      } else {
        setTestConnectionAlert(t('Server is not connected!'));
      }
      setTestConnectionLoading(false);
    }).catch(() => {
      setTestConnectionAlert(t('Server is not connected!'));
      setTestConnectionLoading(false);
    });
  };

  const createMonitor = (newForm?: { url: string, name: string, project_id: number, type: string }) => {
    ApiClient.createMonitor(newForm ? newForm : { ...form, url: host + form.url }).then((res) => {
      MonitorsStore.monitors.push(res.data.monitor);
      history.push(`/project/${newForm ? newForm.project_id : form.project_id}`);
      setIsLoading(false);
    }).catch(() => setIsLoading(false));
  };

  const createProject = () => {
    ApiClient.createProject(newProjectName).then((res) => {
      ProjectsStore.projects.push(res.data.project);
      setForm({ ...form, project_id: res?.data?.project?.id });

      const newForm = { ...form, url: host + form.url };
      newForm.project_id = res?.data?.project?.id;

      createMonitor(newForm);
    });
  };

  const onCreate = () => {
    form.url = host + form.url;
    setIsLoading(true);
    if (newProjectName.length) {
      createProject();
      return;
    }

    createMonitor();
  };

  return (
    <>
      <div className="create-template">
        <div className="title">{t('GraphQL Link')}</div>
        <div className="t-input clue">{t('Follow instructions to setup monitoring')}</div>
        <div className="t-headline-4 pre-input">1. {t('Select project and enter title')}</div>
        <div className="select-project">
          <Field label={t('Project')}>
            {selectProject ? (
              <Select
                options={ProjectsStore.projects.map((p) => p.name)}
                defaultValue={ProjectsStore.projectTabName}
                onChange={selectChangeHandler}
              />
            )
              : (
                <Input
                  value={newProjectName}
                  onChange={(e: any) => setNewProjectName(e.target.value)}
                />
              )}
          </Field>
          <div className="button-add-project" onClick={() => setSelectProject(!selectProject)}>
            {selectProject ? <Icon name="folder" /> : <Icon name="close" /> }
          </div>
        </div>
        <div className="f-title">
          <Field label={t('Title')}>
            <Input
              placeholder={t('Enter title')}
              value={form.name}
              onChange={changeHandler}
              name="name"
            />
          </Field>
        </div>
        <div className="t-headline-4 pre-input">2. {t('Enter server address')}</div>
        <div className="t-input clue">
          {t('ENTER_URL_OR_IP')}
          <br /><br />
          {t('AND_CHECK_CONNECTION')}
        </div>
        <div className="f-title f-server-address">
          <Field label={t('Website address')}>
            <Input
              placeholder={t('Enter website address')}
              value={form.url}
              onEnter={testConnection}
              onChange={changeHandler}
              select={['http://', 'https://']}
              onChangeSelect={setHost}
              name="url"
            />
          </Field>
        </div>
        <div className="server-address-test">
          <Button text={t('Test connection')} bc="main2" size="big" onClick={testConnection} loading={testConnectionLoading} disabled={form.url.length < 1} />
          {testConnectionAlert.length && testConnectionAlert === t('Server is connected!')
            ? <Alert type="success" title={testConnectionAlert} size="small" />
            : testConnectionAlert.length ? <Alert type="warning" title={testConnectionAlert} size="small" /> : ''}
        </div>
      </div>
      <div className="add-monitoring">
        <Button
          text={t('Add monitoring')}
          bc="main18"
          size="big"
          onClick={onCreate}
          loading={isLoading}
          disabled={
          form.url.length < 1 || form.name.length < 1 || !form.project_id || testConnectionAlert !== t('Server is connected!')
        }
        />
      </div>
    </>
  );
});

export default GraphQLCreate;
