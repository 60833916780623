import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Icon from '../Icon';
import Input from '../Form/Input';
import Field from '../Form/Field';
import ApiClient from '../../core/apiClient';

const ResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const submit = () => {
    setLoading(true);

    ApiClient.resetPassword(email).then((res: any) => {
      if (res?.data) {
        history.push('/check-email');
      }

      setLoading(false);
    }).catch(() => setLoading(false));
  };

  return (
    <div className="auth-popup">
      <div className="t-headline-2">{t('Reset password')}</div>
      <div className="t-subtitle-2">
        {t('We’ve sent an email a link to reset your password. That link will expire in 24 hours.')}
      </div>
      <Field label={t('Email')}>
        <Input
          value={email}
          onChange={changeHandler}
          onEnter={submit}
          placeholder={t('Enter email')}
          icon="email"
          cleanable
        />
      </Field>
      <Button text={t('Send reset link to email')} size="big" bc="indigo" loading={loading} onClick={submit} />
      <div className="popup__back" onClick={history.goBack}>
        <Icon name="arrow" />
        <span className="t-button">{t('Back')}</span>
      </div>
    </div>
  );
};

export default ResetPassword;
