import React from 'react';
import getColor from '../scripts/getColor';

interface NamePhotoProps {
  size?: string,
  name: string,
  avatar?: string,
}

const NamePhoto: React.FC<NamePhotoProps> = ({
  size, name, avatar,
}) => {
  const userNoPhoto = () => name.split(' ').map((item: string) => item[0]).join('');

  return (
    <div className={`name-photo ${size ? `size_${size}` : ''} ${getColor(name)}`} style={{ backgroundImage: avatar ? `url(${avatar})` : '' }}>
      {!avatar && userNoPhoto().substr(0, 2)}
    </div>
  );
};

export default NamePhoto;
