import React from 'react';
import { observer } from 'mobx-react-lite';
import NamePhoto from '../NamePhoto';
import MeStore from '../../mobx/me';

const MainInfo = observer(() => (
  <div className="profile__main-info">
    <div className="main-info__first-column">
      <NamePhoto name={MeStore?.me?.name || MeStore.me?.email.split('@')[0] || ''} avatar={MeStore?.me?.avatar} />
    </div>
    <div className="main-info__second-column">
      <div className="main-info__name">{MeStore?.me?.name}</div>
      <div className="main-info__email">{MeStore.me?.email}</div>
    </div>
  </div>
));

export default MainInfo;
