import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { toJS } from 'mobx';
import Icon from '../Icon';
import NamePhoto from '../NamePhoto';
import Popup from '../Popup';
import ProjectsStore from '../../mobx/projects';
import { IProject } from '../../interfaces/project';
import useOnClickOutside from '../../hooks/useOutsideClick';
import Button from '../Button';
import Field from '../Form/Field';
import Input from '../Form/Input';
import Monitors from '../../mobx/monitors';

const Sidebar = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const ref = useRef(null);
  const [projectShow, setProjectShow] = useState(true);
  const [projectDropDownPageY, setProjectDropdownPageY] = useState(0);
  const [dropdown, setDropdown] = useState(false);
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [editPopupShow, setEditPopupShow] = useState(false);
  const [currentProjectSetting, setCurrentProjectSetting] = useState(0);
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    ProjectsStore.getListProjects();
  }, []);

  useOnClickOutside(ref, () => {
    if (dropdown) setDropdown(!dropdown);
  });

  const createProject = () => {
    ProjectsStore.createProject(`${t('Unnamed')}`);
    if (!projectShow) {
      setProjectShow(true);
    }
  };

  const editProject = () => {
    ProjectsStore.loading = true;
    ProjectsStore.updateProject(
      currentProjectSetting,
      { name: editValue },
    )
      .then((res) => {
        setEditPopupShow(false);
        ProjectsStore.projects.filter((p) => p.id === currentProjectSetting)[0].name = res.data.project.name;
        ProjectsStore.projectTabName = res.data.project.name;
        ProjectsStore.loading = false;
      }).catch(() => {
        ProjectsStore.loading = false;
      });
  };

  const editPopupOpen = () => {
    setEditValue(ProjectsStore.projects.filter((p) => p.id === currentProjectSetting)[0].name);
    setEditPopupShow(true);
  };

  const editPopupClose = () => {
    setEditValue('');
    setEditPopupShow(false);
  };

  const deleteProject = () => {
    ProjectsStore.loading = true;
    ProjectsStore.deleteProject(currentProjectSetting).then(() => {
      ProjectsStore.projects = ProjectsStore.projects.filter((p) => p.id !== currentProjectSetting);
      history.push('/');
      setDeletePopupShow(false);
      ProjectsStore.loading = false;
    }).catch(() => {
      ProjectsStore.loading = false;
    });
  };

  const projectDropdown = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    setCurrentProjectSetting(id);
    setProjectDropdownPageY(e.currentTarget.getBoundingClientRect().top - 12);
    setDropdown(e.currentTarget.className === 'dots-free' ? true : !dropdown);
  };

  const reorder = (list: number[], startIndex: number, endIndex: number) => {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    const projectsIdArray = ProjectsStore.projects.map((p) => p.id);

    const oderArray = reorder(projectsIdArray, result.source.index, result.destination.index);

    ProjectsStore.projects.sort((a, b) => oderArray.indexOf(a.id) - oderArray.indexOf(b.id));

    ProjectsStore.reorderProject(oderArray).catch(() => {
      const newOrderArray = reorder(projectsIdArray, result.destination!.index, result.source.index);
      ProjectsStore.projects.sort((a, b) => newOrderArray.indexOf(a.id) - newOrderArray.indexOf(b.id));
    });
  };

  const projectClick = (pid: number) => {
    Monitors.sortMonitorsByProjects(pid);
  };

  const handleClick = () => {
    ProjectsStore.changeTab(0);
  };

  const Projects = observer(() => (
    <div className={`projects ${projectShow ? 'show' : ''}`}>
      <div className="block top-line">
        <div className="projects__heading" onClick={() => setProjectShow(!projectShow)}>
          <div className="t-label-2">{t('Projects')}</div>
          <Icon name="chevron" />
        </div>
        <div className="projects__add" onClick={createProject}>
          <Icon name="plus" />
        </div>
      </div>
      <div className="projects-blocks" ref={ref}>
        {toJS(ProjectsStore.projects)
        && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {toJS(ProjectsStore?.projects).map((project: IProject, index) => (
                    <Draggable key={project.id} draggableId={String(project.id)} index={index}>
                      {(provided) => (
                        <NavLink to={`/project/${project.id}`} activeClassName="active" onClick={() => projectClick(project.id)}>
                          <div
                            className="block project"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="dots-six">
                              <Icon name="dots-six" />
                            </div>
                            <NamePhoto size="small" name={project.name} />
                            <div className="t-button">{project.name}</div>
                            <div className="dots-free" onClick={(e) => projectDropdown(e, project.id)}>
                              <Icon name="dots-three" />
                            </div>
                          </div>
                        </NavLink>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </div>
  ));

  return (
    <>
      <div className="sidebar">
        <div className="logo">
          <div className="logo-icon" />
        </div>
        <nav className="menu">
          <div className="link" onClick={handleClick}>
            <NavLink exact activeClassName="active" to="/">
              <div className="icon-text">
                <Icon name="dashboard" />
                <span className="t-button">{t('Dashboard')}</span>
              </div>
            </NavLink>
          </div>
        </nav>
        <Projects />
        <div className={`project-dropdown ${dropdown ? 'dropdown' : ''}`} style={{ top: `${projectDropDownPageY}px` }}>
          <div onClick={editPopupOpen}>{t('Edit')}</div>
          <div onClick={() => setDeletePopupShow(true)}>{t('Delete')}</div>
        </div>
        <nav className="menu menu__footer">
          <div className="link">
            <NavLink exact to="/help">
              <div className="icon-text">
                <Icon name="help-center" />
                <span className="t-button">{t('Help Center')}</span>
              </div>
            </NavLink>
          </div>
        </nav>
      </div>
      {editPopupShow
      && (
      <Popup onClose={editPopupClose}>
        <div className="popup-edit-project">
          <p className="t-headline-3">{t('Edit project')}</p>
          <p className="t-headline-4">{t('Title')}</p>
          <Field label={t('Enter new project title')}>
            <Input
              value={editValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEditValue(event.target.value)}
              onEnter={editProject}
            />
          </Field>
          <div className="buttons">
            <Button text={t('OK')} size="big" bc="main18" loading={ProjectsStore.loading} onClick={(editProject)} />
            <Button text={t('Cancel')} size="big" bc="transparent" onClick={editPopupClose} />
          </div>
        </div>
      </Popup>
      )}
      {deletePopupShow
      && (
      <Popup onClose={() => setDeletePopupShow(false)}>
        <div className="popup-delete-project">
          <p className="t-headline-3">{t('Delete project')} {ProjectsStore.projects.filter((p) => p.id === currentProjectSetting)[0]?.name}</p>
          <p className="t-body-2">{t('This action cannot be undone')}</p>
          <div className="buttons">
            <Button text={t('Delete')} size="big" bc="red" loading={ProjectsStore.loading} onClick={deleteProject} />
            <Button text={t('Cancel')} size="big" bc="transparent" onClick={() => setDeletePopupShow(false)} />
          </div>
        </div>
      </Popup>
      )}
    </>
  );
});

export default Sidebar;
