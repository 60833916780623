import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Icon from '../Icon';
import Input from '../Form/Input';
import Field from '../Form/Field';

const CreateNewPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="auth-popup">
      <div className="t-headline-2">{t('Create new password')}</div>
      <Field
        label={t('Password')}
        helperText={t('Minimum 8 characters')}
        errorText={t('Wrong password')}
        error={false}
      >
        <Input
          name="password"
          placeholder={t('Enter password')}
          icon="lock"
          cleanable
        />
      </Field>
      <Button text={t('Create & Go to Sign In')} size="big" bc="indigo" />
      <div className="popup__back" onClick={history.goBack}>
        <Icon name="arrow" />
        <span className="t-button">{t('Back')}</span>
      </div>
    </div>
  );
};

export default CreateNewPassword;
