import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import WelcomeSvg from '../../assets/images/welcome.png';
import WelcomeTwoSvg from '../../assets/images/welcome-2.svg';
import WelcomeRSvg from '../../assets/images/welcome-r.svg';
import WelcomeLSvg from '../../assets/images/welcome-l.svg';

const Welcome: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="main-content-welcome">
      <div className="container">
        <div className="main-content-welcome__inner">
          <div className="main-content-welcome__col">
            <div className="main-content-welcome__left">
              <img src={WelcomeLSvg} alt="Welcome L Svg" className="hidden_tablet" />
            </div>
            <div className="main-content-welcome__item">
              <h1 className="main-content-welcome__title t-headline-1">
                {parse(t('main welcome title'))}
              </h1>
            </div>
            <div className="main-content-welcome__item">
              <div className="main-content-welcome__desc t-body-1">
                {parse(t('main welcome desc'))}
              </div>
            </div>
            <div className="main-content-welcome__item">
              <div className="main-content-welcome__control">
                <Link to="/sign-up">
                  <div className="main-content-welcome__button button button_bc-indigo t-button">{t('main welcome button')}</div>
                </Link>
                <div className="main-content-welcome__button-text t-subtitle-1">{t('main welcome button text')}</div>
              </div>
            </div>
          </div>
          <div className="main-content-welcome__col">
            <img src={WelcomeSvg} alt="Welcome Svg" className="hidden" />
            <img src={WelcomeTwoSvg} alt="Welcome Svg" className="show" />
            <div className="main-content-welcome__right">
              <img src={WelcomeRSvg} alt="Welcome R Svg" className="hidden_tablet" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
