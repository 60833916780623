import React from 'react';

const Loader = () => (
  <div className="loader">
    <div className="spinner">
      <div className="spinner--bar" />
      <div className="spinner--bar" />
      <div className="spinner--bar" />
    </div>
  </div>
);

export default Loader;
