import { makeAutoObservable } from 'mobx';
import ApiClient from './apiClient';

class CheckAuth {
  isAuthenticated: null | boolean = null;

  constructor() {
    makeAutoObservable(this);
  }

  checkLocalStorage() {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (!accessToken || !refreshToken) {
      this.logout();
    } else {
      ApiClient.setToken(accessToken, refreshToken);
      ApiClient.toggleSilent(true);

      ApiClient.getMe().then((res) => {
        if (res?.status === 200) {
          this.login();
        } else {
          ApiClient.refreshToken(refreshToken).then((res) => {
            if (res?.status === 200) {
              ApiClient.setToken(res.data.accessToken, res.data.refreshToken);
              this.login();
            } else {
              this.logout();
            }
          });
        }

        ApiClient.toggleSilent(false);
      });
    }
  }

  login() {
    this.isAuthenticated = true;
  }

  logout() {
    this.isAuthenticated = false;

    ApiClient.unsetToken();
  }
}

export default new CheckAuth();
