import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NamePhoto from './NamePhoto';
import { IInvites } from '../interfaces/invites';
import MonitorsStore from '../mobx/monitors';

interface MenuMobileProps {
  invites: IInvites[],
  monitorId: number
}

const MonitorShare: React.FC<MenuMobileProps> = ({ invites, monitorId }) => {
  const [invite, setInvite] = useState<IInvites[]>();
  const { t } = useTranslation();

  useEffect(() => {
    setInvite(invites);
  }, [invites]);

  const userShareRemove = (id: number) => {
    MonitorsStore.monitorInvitesRemove(monitorId, id);
    MonitorsStore.invites = MonitorsStore.invites.filter((m) => m.id !== id);
  };

  const monitor = MonitorsStore.monitors.find((item) => item.id === monitorId);

  return (
    <>
      {invite && invite.map((item) => (
        <div className="popup-share-project__collaborators-item">
          <div className="popup-share-project__collaborators-user">
            <NamePhoto name={item?.user?.name || item?.user?.email.split('@')[0] || ''} size="medium" avatar={item?.user?.avatar ?? ''} />
            <span className={`t-body-2 ${!item.is_invite ? '' : 'no-invite'}`}>{item?.user?.email} {item?.user?.name ? `(${item?.user?.name})` : ''}</span>
          </div>
          {item.user_id === monitor?.user_id ? (
            <div className="t-body-2">{t('Owner')}</div>
          ) : (
            <div className="t-button" onClick={() => userShareRemove(item.id)}>{t('Delete')}</div>
          )}
        </div>
      ))}
      <div></div>
    </>
  );
};

export default MonitorShare;
