import React from 'react';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import SignIn from './SignIn';
import SignUp from './SignUp';
import CheckEmail from './CheckEmail';
import AccountReady from './AccountReady';
import ResetPassword from './ResetPassword';
import CreateNewPassword from './CreateNewPassword';
import Token from './Token';
import Main from '../Main/Main';
import PublicOffer from '../PageText/PublicOffer';
import Terms from '../PageText/Terms';
import InviteProject from '../Invite/InviteProject';
import AfterSignIn from '../AfterSignIn';
import StatusPage from '../Status/StatusPage';

const Auth = () => (
  <div className="auth">
    <Switch>
      <Route path="/" exact>
        <Main />
      </Route>
      <Route path="/invite/:id" exact>
        <InviteProject />
      </Route>
      <Route path="/sign-in" exact>
        <SignIn />
      </Route>
      <Route path="/sign-up" exact>
        <SignUp />
      </Route>
      <Route path="/check-email" exact>
        <CheckEmail />
      </Route>
      <Route path="/account-ready" exact>
        <AccountReady />
      </Route>
      <Route path="/reset-password" exact>
        <ResetPassword />
      </Route>
      <Route path="/create-new-password" exact>
        <CreateNewPassword />
      </Route>
      <Route path="/token" exact>
        <Token />
      </Route>
      <Route path="/public-offer" exact>
        <PublicOffer />
      </Route>
      <Route path="/terms" exact>
        <Terms />
      </Route>
      <Route path="/status" exact>
        <StatusPage />
      </Route>
      <Route path={['/profile/messaging']} exact>
        <AfterSignIn />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  </div>
);

export default Auth;
