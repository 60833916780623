import { makeAutoObservable } from 'mobx';
import ApiClient from '../core/apiClient';
import { INotification } from '../interfaces/notification';

class NotificationsStore {
  open: boolean = false;

  notifications: INotification[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setOpen() {
    this.open = true;
  }

  setClose() {
    this.open = false;
  }

  getList() {
    ApiClient.getListNotifications().then((res) => {
      this.notifications = res?.data.notifications || [];
    });
  }

  toggleNotify(id: number, state: boolean, type: string) {
    switch (type) {
      case 'email':
        this.notifications.filter((n) => n.id === id)[0].is_notify_email = state;
        break;
      case 'telegram':
        this.notifications.filter((n) => n.id === id)[0].is_notify_telegram = state;
        break;
      case 'sms':
        this.notifications.filter((n) => n.id === id)[0].is_notify_sms = state;
        break;
      default:
        break;
    }
    ApiClient.toggleNotify(id, state ? 'on' : 'off', type);
  }
}

export default new NotificationsStore();
