export const interpolate = (fmt: string, obj: any, named = true, useBraces = true) => {
  if (named) {
    let regex; let
      sliceNum: number;

    if (useBraces) {
      regex = /\{\w+\}/g;
      sliceNum = 1;
    } else {
      regex = /%\(\w+\)s/g;
      sliceNum = 2;
    }

    return fmt.replace(regex, (match) => String(obj[match.slice(sliceNum, -sliceNum)]));
  }
  const regex = useBraces ? /\{\}/g : /%s/g;

  return fmt.replace(regex, () => String(obj.shift()));
};
