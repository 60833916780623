import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import LinkIcon from '../../assets/icons/link.svg';
import BoxIcon from '../../assets/icons/box.svg';
import GraphQLIcon from '../../assets/icons/GraphQL.svg';
import ElasticIcon from '../../assets/icons/elastic.svg';
import WrenchIcon from '../../assets/icons/wrench.svg';

const App: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="main-content-price">
      <div className="container">
        <div className="main-content-price__inner">
          <div className="main-content-price__item">
            <div className="main-content-price__title t-headline-1">
              {parse(t('main price title'))}
            </div>
            <div className="main-content-price__desc t-body-2">
              {parse(t('main price desc'))}
            </div>
          </div>
          <div className="main-content-price__item">
            <div className="main-content-price__title t-headline-1">{t('main price title two')}</div>
            <div className="grid">
              <div className="grid__wrapper">
                <div className="grid__item">
                  <div className="grid__block">
                    <div className="grid__header">
                      <div className="grid__header-item">
                        <div className="grid__icon">
                          <img src={LinkIcon} alt={LinkIcon} />
                        </div>
                        <div className="grid__title t-headline-4">{t('AVAIL_TITLE')}</div>
                      </div>
                      <div className="grid__header-item t-body-2">{t('AVAIL_DESCRIPTION')}</div>
                    </div>
                    <div className="grid__body">
                      <div className="grid__body-item">
                        <div className="grid__price t-headline-1">{t('PRICE_$1')}</div>
                        <div className="grid__month t-body-2">/{t('month')}</div>
                      </div>
                      <div className="grid__body-item">
                        <ul className="list">
                          <li className="list__item t-body-2">{t('HTTP code check')}</li>
                          <li className="list__item t-body-2">{t('Collecting Page Load Speed')}</li>
                          <li className="list__item t-body-2">{t('Check frequency is 1 minute')}</li>
                        </ul>
                      </div>
                      <div className="grid__body-item">
                        <Link to="/sign-up">
                          <div className="grid__button t-button">{t('AVAIL_TRY')}</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid__item">
                  <div className="grid__block">
                    <div className="grid__header">
                      <div className="grid__header-item">
                        <div className="grid__icon">
                          <img src={WrenchIcon} alt={WrenchIcon} />
                        </div>
                        <div className="grid__title t-headline-4">{t('CUSTOM_TITLE')}</div>
                      </div>
                      <div className="grid__header-item t-body-2">{t('CUSTOM_DESCRIPTION')}</div>
                    </div>
                    <div className="grid__body">
                      <div className="grid__body-item">
                        <div className="grid__price t-headline-1">{t('PRICE_$1')}</div>
                        <div className="grid__month t-body-2">/{t('month')}</div>
                      </div>
                      <div className="grid__body-item">
                        <ul className="list">
                          <li className="list__item t-body-2">{t('Completely Custom Health Check')}</li>
                          <li className="list__item t-body-2">{t('Set you own threshold alerting')}</li>
                          <li className="list__item t-body-2">{t('Check frequency is 1 minute')}</li>
                        </ul>
                      </div>
                      <div className="grid__body-item">
                        <Link to="/sign-up">
                          <div className="grid__button t-button">{t('CUSTOM_TRY')}</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid__item">
                  <div className="grid__block">
                    <div className="grid__header">
                      <div className="grid__header-item">
                        <div className="grid__icon">
                          <img src={GraphQLIcon} alt={GraphQLIcon} />
                        </div>
                        <div className="grid__title t-headline-4">{t('GRAPH_TITLE')}</div>
                      </div>
                      <div className="grid__header-item t-body-2">{t('GRAPH_DESCRIPTION')}</div>
                    </div>
                    <div className="grid__body">
                      <div className="grid__body-item">
                        <div className="grid__price t-headline-1">{t('PRICE_$1')}</div>
                        <div className="grid__month t-body-2">/{t('month')}</div>
                      </div>
                      <div className="grid__body-item">
                        <ul className="list">
                          <li className="list__item t-body-2">{t('Default GraphQl HealthCheck')}</li>
                          <li className="list__item t-body-2">{t('You know first if something went wrong')}</li>
                          <li className="list__item t-body-2">{t('Check frequency is 1 minute')}</li>
                        </ul>
                      </div>
                      <div className="grid__body-item">
                        <Link to="/sign-up">
                          <div className="grid__button t-button">{t('GRAPH_TRY')}</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid__item">
                  <div className="grid__block">
                    <div className="grid__header">
                      <div className="grid__header-item">
                        <div className="grid__icon">
                          <img src={ElasticIcon} alt={ElasticIcon} />
                        </div>
                        <div className="grid__title t-headline-4">{t('ELASTIC_TITLE')}</div>
                      </div>
                      <div className="grid__header-item t-body-2">{t('ELASTIC_DESCRIPTION')}</div>
                    </div>
                    <div className="grid__body">
                      <div className="grid__body-item">
                        <div className="grid__price t-headline-1">{t('PRICE_$1')}</div>
                        <div className="grid__month t-body-2">/{t('month')}</div>
                      </div>
                      <div className="grid__body-item">
                        <ul className="list">
                          <li className="list__item t-body-2">{t('Default Elastic Search Healthcheck')}</li>
                          <li className="list__item t-body-2">{t('More than two states')}</li>
                          <li className="list__item t-body-2">{t('Check frequency is 1 minute')}</li>
                        </ul>
                      </div>
                      <div className="grid__body-item">
                        <Link to="/sign-up">
                          <div className="grid__button t-button">{t('ELASTIC_TRY')}</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid__item">
                  <div className="grid__block">
                    <div className="grid__header">
                      <div className="grid__header-item">
                        <div className="grid__icon">
                          <img src={BoxIcon} alt={BoxIcon} />
                        </div>
                        <div className="grid__title t-headline-4">{t('AGENT_TITLE')}</div>
                      </div>
                      <div className="grid__header-item t-body-2">{t('AGENT_DESCRIPTION')}</div>
                    </div>
                    <div className="grid__body">
                      <div className="grid__body-item">
                        <div className="grid__price t-headline-1">{t('PRICE_$2')}</div>
                        <div className="grid__month t-body-2">/{t('month')}</div>
                      </div>
                      <div className="grid__body-item">
                        <ul className="list">
                          <li className="list__item t-body-2">{t('Complete server monitoring')}</li>
                          <li className="list__item t-body-2">{t('CPU, Memory, Disk, I/O, Network')}</li>
                          <li className="list__item t-body-2">{t('Check frequency is 1 minute')}</li>
                        </ul>
                      </div>
                      <div className="grid__body-item">
                        <Link to="/sign-up">
                          <div className="grid__button t-button">{t('AGENT_TRY')}</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
