import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import TutorialIcon from '../../assets/images/tutorial.svg';
import Tutorial1Icon from '../../assets/images/tutorial-1.png';

const Tutorial: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="main-content-tutorial">
      <div className="container">
        <div className="main-content-tutorial__item">
          <div className="main-content-tutorial__col">
            <div className="main-content-tutorial__svg">
              <img src={TutorialIcon} alt="Tutorial Icon" />
            </div>
          </div>
          <div className="main-content-tutorial__col">
            <div className="main-content-tutorial__title t-headline-2">
              {parse(t('main tutorial title-1'))}
            </div>
            <div className="main-content-tutorial__desc t-subtitle-1">
              {t('main tutorial desc-1')}
            </div>
          </div>
        </div>
        <div className="main-content-tutorial__item">
          <div className="main-content-tutorial__col">
            <div className="main-content-tutorial__title t-headline-2">
              {parse(t('main tutorial title-2'))}
            </div>
            <div className="main-content-tutorial__desc t-subtitle-1">
              {t('main tutorial desc-2')}
            </div>
          </div>
          <div className="main-content-tutorial__col">
            <div className="main-content-tutorial__svg">
              <img src={Tutorial1Icon} alt="Tutorial 1 Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
