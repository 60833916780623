import { makeAutoObservable } from 'mobx';

class AppStore {
  isPopupOpen: boolean = false;

  redirectTo: string|null = null;

  theme: string = 'light';

  constructor() {
    makeAutoObservable(this);
  }

  setLight() {
    this.theme = 'light';
  }

  setDark() {
    this.theme = 'dark';
  }

  setPopupClosed() {
    this.isPopupOpen = false;
  }

  setPopupOpened() {
    this.isPopupOpen = true;
    window.scrollTo(0, 0);
  }

  setRedirectTo(path: string|null) {
    this.redirectTo = path;
  }
}

export default new AppStore();
