import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MonitorStore from '../../mobx/monitors';
import ProjectsStore from '../../mobx/projects';
import Field from '../Form/Field';
import Input from '../Form/Input';
import SelectProjects from '../Form/SelectProjects';
import Button from '../Button';
import FSPopup from '../FSPopup';
import ApiClient from '../../core/apiClient';
import appStore from '../../mobx/app';
import Alert from '../Alert';

const EditMonitoring = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const monitorId = history.location.pathname.replace(/\D+/, '');
  const monitor = MonitorStore.monitors.find((m) => m.id === Number(monitorId));
  if (!monitor) {
    history.replace('/');
  }
  const project = ProjectsStore.projects.filter((project) => project.id === monitor?.project?.id)[0];
  const [newProjectName, setNewProjectName] = useState('');
  const [form, setForm] = useState({
    url: monitor?.url || '', name: monitor?.name || '', project_id: project?.id || -1, type: 'avail',
  });
  const [selectProject, setSelectProject] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorName, setErrorName] = useState<boolean>(false);
  const [errorProject, setErrorProject] = useState<boolean>(false);
  const [errorURL, setErrorURL] = useState<boolean>(false);
  const [errorNameText, setErrorNameText] = useState<string>('');
  const [errorProjectText, setErrorProjectText] = useState<string>('');
  const [errorURLText, setErrorURLText] = useState<string>('');
  const [test, setTest] = useState(false);
  const [testConnectionAlert, setTestConnectionAlert] = useState('');
  const [testConnectionLoading, setTestConnectionLoading] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const selectChangeHandler = (value: string) => {
    setForm({ ...form, project_id: ProjectsStore.projects.filter((p) => p.name === value)[0]?.id });
  };

  useEffect(() => {
    setForm({ ...form, project_id: project?.id });
  }, [project?.id]);

  const createMonitor = (newForm?: { url: string, name: string, project_id: number }) => {
    ApiClient.editMonitor(newForm ? newForm : { ...form, url: form.url }, Number(monitorId)).then((res: any) => {
      if (res?.data) {
        MonitorStore.reachMonitors([res.data.monitor]);
        history.push(`/monitor/${res?.data?.monitor?.id}`);
        appStore.setPopupClosed();
      }

      if (res.response.data.msg) {
        if (typeof res.response.data.msg === 'object') {
          Object.keys(res.response.data.msg).forEach((err: string) => {
            if (err === 'name') {
              setErrorNameText(res.response?.data.msg[err][0]);
              setErrorName(true);
            }
          });
        }
      }

      setIsLoading(false);
    }).catch(() => setIsLoading(false));
  };

  const createProject = () => {
    ApiClient.createProject(newProjectName).then((res) => {
      ProjectsStore.projects.push(res.data.project);
      setForm({ ...form, project_id: res?.data?.project?.id });

      const newForm = { ...form, url: form.url };
      newForm.project_id = res?.data?.project?.id;

      createMonitor(newForm);
    });
  };

  const testConnection = () => {
    setTestConnectionLoading(true);
    setErrorURL(false);
    ApiClient.testConnection(form.type, form.url).then((res) => {
      if (res.data?.result === true) {
        setTestConnectionAlert(t('Website is connected!'));
        setTest(true);
      } else if (res.data?.state?.error?.length > 0) {
        setTest(false);
        setTestConnectionAlert(res.data?.state?.error);
      } else {
        setTest(false);
        setTestConnectionAlert(t('Website is not connected!'));
      }
      setTestConnectionLoading(false);
      setIsLoading(false);
    }).catch(() => {
      setTest(false);
      setTestConnectionAlert(t('Website is not connected!'));
      setTestConnectionLoading(false);
      setIsLoading(false);
    });
  };

  const onCreate = () => {
    setErrorURL(false);
    setErrorURLText('');
    setErrorNameText('');
    setErrorName(false);
    setErrorProject(false);
    setErrorProjectText('');
    setErrorURL(false);

    if (test) {
      setIsLoading(true);
      if (!selectProject) {
        if (newProjectName) {
          createProject();
        } else {
          setIsLoading(false);
          setErrorProject(true);
          setErrorProjectText(t('The name project field is required'));
        }
        return;
      }

      if (form.url) {
        createMonitor();
      } else {
        setIsLoading(false);
        setErrorURL(true);
        setErrorURLText(t('The url field is required'));
      }
    } else {
      setErrorURL(true);
    }
  };

  return (
    <FSPopup back={t('Back')}>
      <div className="edit-monitoring">
        <div className="t-headline-2">{t('Edit monitor')}</div>
        <div className="t-headline-4 pre-input">1. {t('Select project and enter title')}</div>
        <div className="form">
          <form>
            <SelectProjects
              selectChangeHandler={selectChangeHandler}
              setNewProjectName={setNewProjectName}
              setSelectProjects={setSelectProject}
              value={project?.id}
              error={errorProject}
              errorText={errorProjectText}
            />
            <Field label={t('Title')} error={errorName} errorText={errorNameText}>
              <Input
                placeholder={t('Enter title')}
                value={form.name}
                onChange={changeHandler}
                name="name"
                onEnter={onCreate}
              />
            </Field>
            <div className="t-headline-4 pre-input">2. {t('Enter website address')}</div>
            <div className="t-input clue">
              {t('ENTER_URL_OR_IP')}
              <br /><br />
              {t('AND_CHECK_CONNECTION')}
            </div>
            <Field label={t('Website address')} error={errorURL} errorText={errorURLText}>
              <Input
                placeholder={t('Enter website address')}
                noHost
                value={form.url}
                onChange={changeHandler}
                name="url"
                onEnter={onCreate}
              />
            </Field>
            <div className="server-address-test">
              <Button text={t('Test connection')} bc="main2" size="big" onClick={testConnection} loading={testConnectionLoading} disabled={form.url.length < 1} />
              {testConnectionAlert.length && testConnectionAlert === t('Website is connected!')
                ? <Alert type="success" title={testConnectionAlert} size="small" />
                : testConnectionAlert.length ? <Alert type="warning" title={testConnectionAlert} size="small" />
                  : <Alert type="success-default" title={t('Not tested!')} size="small" />}
            </div>
            <Button text={t('Save')} bc="main18" size="big" onClick={onCreate} loading={isLoading} disabled={!test} />
          </form>
        </div>
      </div>
    </FSPopup>
  );
});

export default EditMonitoring;
