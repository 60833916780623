import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import EventsStore from '../../mobx/events';

const Tabs: React.FC = observer(() => {
  const { t } = useTranslation();

  const tabsName = [
    {
      id: 0,
      name: t('All'),
      level: 'all',
    },
    {
      id: 1,
      name: t('Error'),
      level: 'error',
    },
    {
      id: 2,
      name: t('Warning'),
      level: 'warning',
    },
    {
      id: 3,
      name: t('Info'),
      level: 'info',
    },
  ];

  const [tabActive, setTabActive] = useState('all');

  useEffect(() => {
    EventsStore.setEventTab(tabActive);
  }, [tabActive]);

  return (
    <div className="tabs">
      {tabsName.map((tab) => (
        <div key={`notificationTab#${tab.id}`} className={`tab t-button ${tabActive === tab.level ? 'active' : ''}`} onClick={() => setTabActive(tab.level)}>{tab.name}</div>
      ))}
    </div>
  );
});

export default Tabs;
