import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Field from '../Form/Field';
import Input from '../Form/Input';
import Button from '../Button';
import Icon from '../Icon';
import ApiClient from '../../core/apiClient';
import CheckAuth from '../../core/checkAuth';

const Token = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [token, setToken] = useState('');
  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToken(event.target.value);
  };

  useEffect(() => {
    const tokenParam = history.location.search;

    if (tokenParam.includes('token')) {
      const token = tokenParam.split('=')[1];
      ApiClient.setToken(token, token);
      CheckAuth.login();
      history.push('/profile/password');
    }
  }, []);

  const onEnter = () => {
    if (token) {
      setError(false);
      setErrorText('');
      ApiClient.setToken(token, token);

      ApiClient.getMe().then((res) => {
        if (res?.status === 200) {
          CheckAuth.login();
          history.push('/profile/password');
        } else {
          setError(true);
          setErrorText(t('Invalid token'));
        }
      });
    } else {
      setError(true);
      setErrorText(t('Fill in the field'));
    }
  };

  return (
    <div className="auth-popup">
      <div className="t-headline-2">{t('Enter your token')}</div>
      <Field label={t('Token')} error={error} errorText={errorText}>
        <Input
          value={token}
          onChange={changeHandler}
          onEnter={onEnter}
          placeholder={t('Enter token')}
        />
      </Field>
      <Button text={t('Reset password')} size="big" bc="main18" onClick={onEnter} />
      <div className="popup__back" onClick={history.goBack}>
        <Icon name="arrow" />
        <span className="t-button">{t('Back')}</span>
      </div>
    </div>
  );
};

export default Token;
