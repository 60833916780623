import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import EmailIcon from '../../assets/icons/email.svg';
import SmsIcon from '../../assets/icons/sms.svg';
import TelegramIcon from '../../assets/icons/telegram.svg';
import WebhookIcon from '../../assets/icons/webhook.svg';

const Notify: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="main-content-notify">
      <div className="container">
        <div className="main-content-notify__inner">
          <div className="main-content-notify__col">
            <div className="main-content-notify__item">
              <div className="main-content-notify__title t-headline-2">{parse(t('main notify title'))}</div>
            </div>
            <div className="main-content-notify__item">
              <div className="main-content-notify__desc t-subtitle-1">{t('main notify desc')}</div>
            </div>
          </div>
          <div className="main-content-notify__col">
            <div className="grid">
              <div className="grid__row">
                <div className="grid__item">
                  <div className="grid__inner">
                    <div className="grid__block">
                      <div className="grid__icon">
                        <img src={EmailIcon} alt="Email Icon" />
                      </div>
                      <div className="grid__text t-headline-3">Email</div>
                    </div>
                  </div>
                </div>
                <div className="grid__item">
                  <div className="grid__inner">
                    <div className="grid__block">
                      <div className="grid__icon">
                        <img src={SmsIcon} alt="Sms Icon" />
                      </div>
                      <div className="grid__text t-headline-3">SMS</div>
                    </div>
                  </div>
                </div>
                <div className="grid__item show_tablet">
                  <div className="grid__inner">
                    <div className="grid__block">
                      <div className="grid__icon">
                        <img src={WebhookIcon} alt="Telegram Icon" />
                      </div>
                      <div className="grid__text t-headline-3">Webhook</div>
                    </div>
                  </div>
                </div>
                <div className="grid__item">
                  <div className="grid__inner">
                    <div className="grid__block">
                      <div className="grid__icon">
                        <img src={TelegramIcon} alt="Telegram Icon" />
                      </div>
                      <div className="grid__text t-headline-3">Telegram</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid__row hidden hidden_tablet">
                <div className="grid__item">
                  <div className="grid__inner">
                    <div className="grid__block">
                      <div className="grid__icon">
                        <img src={WebhookIcon} alt="Webhook Icon" />
                      </div>
                      <div className="grid__text t-headline-3">Webhook</div>
                    </div>
                    <div className="grid__content">
                      <div className="grid__desc t-body-1">
                        {t('main notify grid title')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notify;
