import React from 'react';
import { useTranslation } from 'react-i18next';
import FSPopup from '../FSPopup';

const BillingSuccess = () => {
  const { t } = useTranslation();

  return (
    <FSPopup back={t('Return')} close="/profile/billing/">
      <div className="auth-popup">
        <div className="auth-popup__icon account-ready" />
        <div className="t-headline-2">{t('Your payment was received!')}</div>
      </div>
    </FSPopup>
  );
};

export default BillingSuccess;
