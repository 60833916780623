import axios, { AxiosError, AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import CheckAuth from './checkAuth';
import { IMonitor } from '../interfaces/monitor';
import { IProject } from '../interfaces/project';
import appStore from '../mobx/app';

class ApiClient {
  private client: AxiosInstance;

  private todo: AxiosInstance;

  private silent: boolean = false;

  constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 60000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.todo = axios.create({
      baseURL: 'https://todo.eveli.net',
      timeout: 60000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!this.silent) {
          this.handleError(error);
        }
        return error;
      },
    );

    this.todo.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!this.silent) {
          this.handleError(error);
        }
        return error;
      },
    );
  }

  toggleSilent = (silent: boolean) => {
    this.silent = silent;
  };

  handleError(error: AxiosError) {
    if (error.response?.status === 401) {
      appStore.setRedirectTo(window.location.pathname + window.location.search);
      CheckAuth.logout();
      toast.error(t('NOT_AUTHORIZED'));
      return;
    }

    if (error.response?.data) {
      return;
    }

    switch (error.response?.status) {
      case 500:
        toast.error('An error has occurred on the server. Try later');
        break;
      default:
        toast.error('An error has occurred.. Please try again');
        break;
    }
  }

  requestData = async (config: any) => this.client.request(config);

  setToken(accessToken: string, refreshToken?: string) {
    localStorage.setItem('accessToken', accessToken);
    if (refreshToken) localStorage.setItem('refreshToken', refreshToken);

    this.client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  unsetToken() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    this.client.defaults.headers.common.Authorization = '';
  }

  login = async (data: any) => await this.requestData({
    method: 'post',
    url: 'auth/login',
    data,
  });

  register = async (data: any) => await this.requestData({
    method: 'post',
    url: 'auth/register',
    data,
  });

  logout = async () => await this.requestData({
    method: 'post',
    url: 'auth/logout',
  });

  resetPassword = async (email: string) => await this.requestData({
    method: 'post',
    url: 'auth/passwordRecovery',
    data: {
      email,
    },
  });

  refreshToken = async (refreshToken: string) => await this.requestData({
    method: 'post',
    url: 'auth/refresh',
    data: {
      refreshToken,
    },
  });

  getMe = async () => await this.requestData({
    method: 'get',
    url: 'me',
  });

  updateMe = async (data: any) => await this.requestData({
    method: 'put',
    url: 'me',
    data,
  });

  changePassword = async (data: any) => await this.requestData({
    method: 'put',
    url: 'me/password',
    data,
  });

  uploadAvatar = async (file: FormData) => await this.requestData({
    method: 'post',
    url: 'me/avatar',
    data: file,
  });

  addPhone = async (phone: string) => await this.requestData({
    method: 'post',
    url: 'me/phones',
    data: {
      phone,
    },
  });

  deletePhone = async (phone: string) => await this.requestData({
    method: 'delete',
    url: 'me/phones',
    data: {
      phone,
    },
  });

  validatePhone = async (phone: string, code: number) => await this.requestData({
    method: 'put',
    url: 'me/phones/validate',
    data: {
      phone,
      code,
    },
  });

  getListProjects = async () => await this.requestData({
    method: 'get',
    url: 'projects',
  });

  createProject = async (name: string) => await this.requestData({
    method: 'post',
    url: 'projects',
    data: {
      name,
    },
  });

  updateProject = async (id: number, data: IProject) => await this.requestData({
    method: 'put',
    url: `projects/${id}`,
    data,
  });

  deleteProject = async (id: number) => await this.requestData({
    method: 'delete',
    url: `projects/${id}`,
  });

  orderProject = async (array: number[]) => await this.requestData({
    method: 'put',
    url: 'projects/order',
    data: {
      projects: array,
    },
  });

  createMonitor = async (data: any) => await this.requestData({
    method: 'post',
    url: 'monitors',
    data,
  });

  editMonitor = async (data: any, id: number) => await this.requestData({
    method: 'put',
    url: `monitors/${id}`,
    data,
  });

  getListMonitors = async (limit: number, offset: number) => await this.requestData({
    method: 'get',
    url: 'monitors',
    params: {
      limit,
      offset,
    },
  });

  getRates = async (currency: string) => await this.requestData({
    method: 'get',
    url: 'monitors/rates',
    params: {
      currency,
    },
  });

  getMonitorsByProject = async (id: number) => await this.requestData({
    method: 'get',
    url: `projects/${id}/monitors`,
  });

  getMonitor = async (id: number) => await this.requestData({
    method: 'get',
    url: `monitors/${id}`,
  });

  getMonitorInvites = async (id: number) => await this.requestData({
    method: 'get',
    url: `monitors/${id}/invites`,
  });

  monitorInvitesAdd = async (id: number, emails: string[]) => await this.requestData({
    method: 'post',
    url: `monitors/${id}/invites`,
    data: {
      emails,
    },
  });

  monitorInvitesRemove = async (id: number, userId: number) => await this.requestData({
    method: 'delete',
    url: `monitors/${id}/invites/${userId}`,
  });

  getMonitorInvitesAccept = async (id: number) => await this.requestData({
    method: 'put',
    url: `monitors/${id}/invites/accept`,
  });

  getMonitorInvitesRefuse = async (id: number) => await this.requestData({
    method: 'put',
    url: `monitors/${id}/invites/refuse`,
  });

  updateMonitor = async (id: number, data: IMonitor) => await this.requestData({
    method: 'put',
    url: `monitors/${id}`,
    data,
  });

  deleteMonitor = async (id: number) => await this.requestData({
    method: 'delete',
    url: `monitors/${id}`,
  });

  stopMonitor = async (id: number) => await this.requestData({
    method: 'put',
    url: `monitors/${id}/stop`,
  });

  startMonitor = async (id: number) => await this.requestData({
    method: 'put',
    url: `monitors/${id}/start`,
  });

  testConnection = async (type: string, url: string) => await this.requestData({
    method: 'post',
    url: 'monitors/test',
    data: {
      type,
      url,
    },
  });

  getListEvents = async () => await this.requestData({
    method: 'get',
    url: 'events/list',
  });

  readEvents = async () => await this.requestData({
    method: 'put',
    url: 'events/read',
  });

  getListNotifications = async () => await this.requestData({
    method: 'get',
    url: 'notifications/list',
  });

  toggleNotify = async (id: number, state: string, type: string) => await this.requestData({
    method: 'put',
    url: `notifications/toggle/${id}/${type}/${state}`,
  });

  getNotificationsMonitor = async (monitor_id: number, state: string) => await this.requestData({
    method: 'put',
    url: `notifications/toggle/${monitor_id}/${state}`,
  });

  initPayment = async () => await this.client.request({
    method: 'post',
    url: 'payments',
    data: {
      amount: 1,
    },
  });

  // TODO CLIENT
  createTicket = async (description: string, user_id: number) => await this.todo.request({
    method: 'post',
    url: 'api/1.0/task/monitoringIssue',
    data: {
      description,
      user_id,
    },
  });

  getPayment = async (start_date: string, end_date: string) => await this.requestData({
    method: 'get',
    url: 'payments/transactions/',
    params: {
      start_date,
      end_date,
    },
  });

  getStatus = async () => await this.requestData({
    method: 'get',
    url: 'status',
  });

  removePaymentMethod = async (methodId: number) => await this.client.request({
    method: 'delete',
    url: `payments/method/${methodId}`,
  });
}

export default new ApiClient();
