import React, { useEffect, useState } from 'react';

const Scroll = (container: any) => {
  // eslint-disable-next-line react/destructuring-assignment
  const div = container?.container?.current;

  const [height, setHeight] = useState('0%');
  const [top, setTop] = useState('0%');

  const handleScroll = () => {
    setTop(`${(div.scrollTop / div.scrollHeight) * 100}%`);
  };

  useEffect(() => {
    if (div) {
      const h = (div.clientHeight / div.scrollHeight) * 100;
      setHeight(`${h !== 100 ? h : 0}%`);
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      div?.removeEventListener('scroll', handleScroll);
    };
  }, [container]);

  return (
    <div className="scroll">
      <div className="scrollBar" style={{ height, top }} />
    </div>
  );
};

export default Scroll;
