import React from 'react';
import { useTranslation } from 'react-i18next';
import FSPopup from '../FSPopup';

const Terms: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FSPopup back={t('Back')}>
      <div className="page-text">
        <h1 className="page-text__title t-headline-2">Пользовательское соглашение</h1>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>1. Общие положения</strong></p>
          <p>1.1. Настоящий документ «Пользовательское соглашение» регулирует отношения, возникающие между ИП Коцик М.Ю.  (далее – Исполнитель), являющимся правообладателем Сайта https://whatcha.me в интернете, и Заказчиком.</p>
          <p>1.2. Исполнитель размещает на Сайте информацию о предлагаемых услугах и иную информацию, связанную с деятельностью Исполнителя. Информация об услугах, размещенная на Сайте, действительна до внесения в нее изменений Исполнителем, если иное не указано дополнительно. Внесение изменений в сведения об оказании Услуг, размещенные на Сайте, производится в одностороннем порядке без уведомления Пользователя.</p>
          <p>1.3. Пользователь обязуется полностью ознакомиться с настоящим Соглашением до момента отправления Заявки на покупку услуги. Оформление Заявки означает полное и безоговорочное принятие Заказчиком положений настоящего Соглашения.</p>
          <p>1.4. Отправляя Заявку в установленном порядке, Заказчик полностью и безоговорочно соглашается с настоящим Соглашением.</p>
        </div>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>2. Используемые термины</strong></p>
          <p>2.1. Посетитель – лицо, пришедшее на Сайт без цели разместить Заявку.</p>
          <p>2.2. Пользователь – посетитель Сайта, желающий разместить Заявку на Сайте и принимающий условия настоящего Соглашения.</p>
          <p>2.3. Соглашение – настоящий документ со всеми дополнениями и изменениями.</p>
          <p>2.4. Заказчик – посетитель Сайта, разместивший Заявку на Сайте.</p>
          <p>2.5. Заявка – должным образом заполненный запрос на оказание выбранных на Сайте Услуг с указанием данных Заказчика.</p>
          <p>2.6. Услуги – информационные услуги, оказываемые Исполнителем и доступные для Заявки на Сайте.</p>
          <p>2.7. Исполнитель – юридическое лицо, информация об услугах которого размещена на Сайте. Сведения об Исполнителе: ИП Коцик Михаил Юрьевич (<i>ИНН: 781699527245, ОГРНИП: 316784700316317, Юридический адрес: 192071, Россия, Санкт-Петербург, ул. Бухарестская, д.33, к.5, кв.22</i>)</p>
          <p>2.8. Сайт – ресурс whatcha.me , расположенный по адресу https://whatcha.me, на котором расположена информация об Услугах, созданный и действующий с целью предоставить Посетителям, Пользователям и Заказчикам информацию о предоставляемых Исполнителем услугах, обработать заявку Заказчиков для последующего предложения услуг.</p>
        </div>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>3. Защита персональной информации</strong></p>
          <p>3.1. Предоставляя свои персональные данные при отправке Заявки, Заказчик дает Исполнителю свое согласие на обработку и использование своих персональных данных согласно ФЗ №152-ФЗ «О персональных данных» от 27.07.2006 г. в целях, указанных в настоящем Соглашении.</p>
          <p>3.2. Персональные данные, которые собирает Исполнитель:</p>
          <ul>
            <li>имя;</li>
            <li>доменное имя Сайта;</li>
            <li>адрес ящика электронной почты;</li>
            <li>номер мобильного телефона;</li>
          </ul>
          <p>3.3. Исполнитель использует персональные данные в целях:</p>
          <ul>
            <li>оформления Заказчиком Заявки на Сайте;</li>
            <li>обратной связи с Заказчиком.</li>
          </ul>
          <p>3.4. Исполнитель обязуется не разглашать полученную от Заказчика информацию, в Заявке или иным способом. Не считается нарушением настоящего Соглашения и обязательств Исполнителя разглашение информации Заказчика, когда обязанность такого разглашения обусловлено требованиями действующего законодательства Российской Федерации.</p>
        </div>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>4. Заключительные положения</strong></p>
          <p>4.1. Исполнитель оставляет за собой право вносить изменения в настоящее Соглашения в одностороннем порядке без уведомления Заказчика. Внесенные в Соглашение изменения вступают в силу в момент их публикации на Сайте.</p>
        </div>
      </div>
    </FSPopup>
  );
};

export default Terms;
