import React from 'react';
import { useTranslation } from 'react-i18next';
import FSPopup from '../FSPopup';

const PublicOffer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FSPopup back={t('Back')}>
      <div className="page-text">
        <h1 className="page-text__title t-headline-2">Публичная оферта</h1>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>1. Общие положения</strong></p>
          <p>1.1. Настоящий публичный договор (далее – «<strong>Оферта</strong>» или «<strong>Договор</strong>») представляет собой официальное предложение Администрации Сайта whatcha.me, далее именуемой «<strong>Исполнитель</strong>», в лице Индивидуального предпринимателя Коцика Михаила Юрьевича, по оказанию Услуг проверки доступности сайта с помощью программного обеспечения и информационных баз данных Сайта физическим и юридическим лицам, признаваемым «<strong>Заказчиками</strong>» в соответствии с условиями настоящей Оферты.</p>
          <p>1.2. Исполнитель и Заказчик для целей настоящего Договора далее совместно именуются «Стороны».</p>
          <p>1.3. В соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации (далее – ГК РФ) данный документ является публичной Офертой и в случае принятия изложенных ниже условий и оплаты услуг Исполнителя лицо, осуществившее Акцепт настоящей Оферты, становится Заказчиком.</p>
          <p>1.4. В соответствии с пунктом 1 статьи 438 ГК РФ Акцепт должен быть полным и безоговорочным.</p>
          <p>1.5. В соответствии с пунктом 3 статьи 438 ГК РФ совершение лицом, получившим Оферту, в срок, установленный для ее акцепта (в период действия настоящей Оферты), действий по выполнению указанных в ней условий Договора считается акцептом настоящей публичной Оферты.</p>
          <p>1.6. Исполнитель и Заказчик предоставляют взаимные гарантии своей право- и дееспособности, необходимые для заключения и исполнения настоящего Договора.</p>
        </div>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>2. Определения и термины </strong></p>
          <p>2.1. В целях настоящей Оферты нижеприведенные термины используются в следующем значении:</p>
          <p>Оферта – настоящий публичный Договор на оказание услуг Заказчику.</p>
          <p>Сайт – Сайт whatcha.me, на котором размещена настоящая Оферта.</p>
          <p>Услуги – оказываемые Исполнителем Услуги, содержание и условия оказания которых приведены на Сайте, получить которую намерен Заказчик.</p>
          <p>Акцепт Оферты – полное и безоговорочное принятие настоящей публичной Оферты путем осуществления действий по получению Услуги и осуществлению ее оплаты безналичной форме по реквизитам Исполнителя, указанным на Сайте.</p>
          <p>Подписка— предоставление Пользователю на условиях Договора возможности за плату в течение ограниченного промежутка времени получить определенное количество Услуг.</p>
          <p>Заказчик – физическое или юридическое лицо, осуществившее Акцепт настоящей изложенных в ней условиях.</p>
          <p>Договор – настоящий Договор между Заказчиком и Исполнителем Услуг, который заключается посредством Акцепта настоящей Оферты.</p>
        </div>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>3. Предмет Оферты </strong></p>
          <p>3.1. Предметом настоящей Оферты является оказание Заказчику Услуг силами Исполнителя в соответствии с условиями настоящей Оферты.</p>
          <p>3.2. Стоимость Услуг указана на Сайте <a href="https://whatcha.me/">https://whatcha.me/</a></p>
        </div>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>4. Условия и порядок оказания Услуг</strong></p>
          <p>4.1. Текст Оферты, постоянно размещенный на Сайте, содержит все существенные условия и является предложением Исполнителя заключить Договор с любым полностью дееспособным физическим лицом, использующим Сайт, на указанных в тексте Оферты условиях. Настоящий документ является публичной офертой в соответствии с российским законодательством.</p>
          <p>4.2. Обязательным условием оказания Исполнителем Услуг в рамках Подписки является принятие и соблюдение Пользователем, применение к отношениям Сторон по Договору требований и положений, изложенных в Оферте.</p>
          <p>4.3. Порядок заключения Договора:</p>
          <p>4.3.1. Пользователь знакомится с условиями Оферты, информацией об Исполнителе, об Услугах, доступных в рамках Подписки, и условиях их предоставления, которые можно получить в рамках Подписки, а также с информацией о Стоимости подписки, и о Периоде действия подписки, размещенной на Сайте.</p>
          <p>4.3.2. Пользователь проходит процедуру авторизации на Сайте.</p>
          <p>4.3.3. Пользователь проходит процедуру привязки банковской карточки и/или электронного средства платежа к своему Личному кабинету (Привязанная карта). Для целей настоящей Оферты любая банковская карта и/или электронное средство платежа, привязанные к Личному кабинету (в том числе привязанные при оформлении Подписки до её оформления, либо после её оформления) считаются Привязанной картой. Исполнитель, либо уполномоченное им лицо вправе списать сумму, составляющую Стоимость подписки с любой из Привязанных карт.</p>
          <p>Для подтверждения действительности Привязанной карты может быть осуществлено списание (блокировка) минимальной суммы, которая в случае успешного прохождения транзакции возвращается Пользователю. Неуспешная попытка списания указанной суммы означает невозможность добавления данной Привязанной карты и оформления/оплаты подписки.</p>
          <p>4.3.4. Пользователь после совершения действий, указанных в п.4.3.1. — п.4.3.3. Оферты, осуществляет акцепт Оферты путём нажатия кнопки «Добавить способ оплаты».</p>
          <p>4.4. Если иное не предусмотрено в Личном кабинете, Стоимость подписки взимается после окончания очередного Периода подписки (месяц), в зависимости от количества дней, в которые были оказаны Услуги.</p>
          <p>Стоимость подписки делится равными частями на количество календарных дней в текущем Периоде Подписки. По окончанию периода начисляется оплата в зависимости от количества дней использования Услуг на Сайте.</p>
          <p>Период подписки, равный месяцу, начинается с даты оформления Подписки и истекает в соответствующее число календарного месяца, идущего перед датой оплаты Подписки.</p>
          <p>4.5. Оплата Стоимости подписки производится Пользователем в порядке, предусмотренном настоящим пунктом. При указании данных Привязанной карты и дальнейшем использовании Привязанной карты Пользователь подтверждает и гарантирует указание им достоверной и полной информации о действительной банковской карте, выданной на его имя; соблюдение им правил международных платежных систем и требований банка-эмитента, выпустившего Привязанную карту, в том числе в отношении порядка проведения безналичных расчетов; указание им достоверной и полной информации об электронном платежном средстве; соблюдение требований оператора электронных денег.</p>
          <p>4.6. Пользователь понимает и соглашается с тем, что Подписка по умолчанию оформляется бессрочно с момента оплаты первого Периода подписки. Пользователь вправе отказаться от продления Подписки на следующий Период подписки в Личном кабинете. В таком случае доступ к заказу Пользователем Услуг в рамках Подписки прекращается со дня, следующего за последним днем использования Услуг. Кроме того, Подписка может быть прекращена Исполнителем в иных случаях либо по иным основаниям, предусмотренным настоящей Офертой.</p>
          <p>4.7. В случае отсутствия достаточной суммы денежных средств на Привязанной карте для продления Подписки, Исполнитель вправе сохранить возможность доступа Пользователя к Подписке до момента списания абонентской платы с Привязанной карты. Отсутствие достаточной суммы денежных средств на Привязанной карте на момент оплаты, Исполнитель вправе считать отказом Пользователя от продления Подписки с даты начала неоплаченного Периода подписки.</p>
          <p>4.8. Пользователь, принимая условия настоящей Оферты, даёт своё согласие на автоматическое периодическое списание денежных средств с его счета в оплату Подписки, и признает, что распоряжения на списание денежных средств с его счета, направленные в соответствии с настоящим пунктом Оферты, являются распоряжениями самого Пользователя, а действия процессингового центра и банка-эквайера, направленные на списание денежных средств в соответствии с настоящим пунктом Оферты, выполнены с согласия Пользователя.</p>
          <p>Списание денежных средств в соответствии с настоящим пунктом Оферты начинает происходить на автоматической основе при соблюдении следующих условий:</p>
          <ul>
            <li>ввода Пользователем всех необходимых реквизитов Привязанной карты;</li>
            <li>активации Сервиса по списанию денежных средств на автоматической основе с Привязанной карты следующим способом: автоматически при совершении первого платежа;</li>
            <li>нажатия кнопки «Добавить способ оплаты» (или иной аналогичной по функционалу кнопки), подтверждающего согласие Пользователя с настоящей Офертой.</li>
          </ul>
          <p>4.9. Оформив Подписку, Пользователь считается принявшим условия Оферты, а также положения документов, на которые ссылается настоящая Оферта, в полном объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с положениями указанных документов, Пользователь обязан прекратить Подписку в Личном кабинете.</p>
          <p>4.10. Доступ к Подписке считается предоставленным Пользователю на Период подписки в полном объеме с момента оформления Пользователем подписки на Сайте.</p>
          <p>Обязательства Исполнителя по предоставлению Пользователю доступа к Подписке считаются выполненными перед Пользователем независимо от того, было ли затребовано Пользователем соответствующее исполнение от Исполнителя в Период подписки, а также независимо от фактического количества и объема оказанных Пользователю соответствующих Услуг в рамках Периода подписки.</p>
          <p>4.11. Порядок оказания Услуг по Подписке</p>
        </div>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>5. Права и обязанности Исполнителя </strong></p>
          <p>5.1. Исполнитель обязуется предоставлять Заказчику доступ к разделам Сайта, необходимым для получения заказанных и оплаченных Услуг, с использованием учетной записи Заказчика.</p>
          <p>5.2. Исполнитель несет ответственность за хранение и обработку персональных данных Заказчика, обеспечивает сохранение конфиденциальности этих данных и использует их исключительно для качественного оказания Услуг Заказчику.</p>
          <p>5.3. Исполнитель оставляет за собой право изменять условия настоящей Оферты в одностороннем порядке без предварительного уведомления Заказчика, публикуя указанные изменения на Сайте. Новые / измененные условия, указываемые на Сайте, действуют в отношении всех заключенных и заключаемых Договоров.</p>
          <p>5.4. Исполнитель вправе заблокировать учетную запись Заказчика в случае нарушения Заказчиком правил использования Услуг без возвращения внесенной платы.</p>
        </div>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>6. Права и обязанности заказчика </strong></p>
          <p>6.1. Заказчик обязан предоставлять достоверную информацию о себе при получении соответствующих Услуг.</p>
        </div>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>7. Ответственность Исполнителя. Рассмотрение претензий </strong></p>
          <p>7.1. За неисполнение либо ненадлежащее исполнение обязательств по настоящей Оферте, Стороны несут ответственность в соответствии с законодательством Российской Федерации.</p>
          <p>7.2. Исполнитель не несет ответственности за непредставление (некачественное предоставление) Услуг по причинам, не зависящим от Исполнителя.</p>
          <p>7.3. В случае, если Заказчик оплатил Услуги, но по причинам, не зависящим от Исполнителя, не воспользовался ими, возврат денежных средств не осуществляется.</p>
          <p>7.4. Все претензии к качеству оказываемых Услуг, в том числе любые претензии, содержащие требование о возврате денежных средств, должны направляться Заказчиком Исполнителю. Срок рассмотрения претензии Заказчика составляет 30 (тридцать) календарных дней с момента получения претензии Исполнителем.</p>
          <p>7.5. Исполнитель и Заказчик, принимая во внимания характер оказываемых Услуг, обязуются в случае возникновения споров и разногласий, связанных с оказанием Услуг, применять досудебный порядок урегулирования спора (переговоры, переписка). В случае невозможности урегулирования спора в досудебном порядке Стороны вправе обратиться в суд по месту нахождения Исполнителя.</p>
        </div>
        <div className="page-text__block">
          <p className="page-text__block-title t-headline-3"><strong>8. Срок действия Оферты. Заключительные положения </strong></p>
          <p>8.1 Настоящая Оферта вступает в силу с момента опубликования на Сайте в сети Интернет и действует до момента отзыва/изменения Оферты Исполнителем.</p>
        </div>
      </div>
    </FSPopup>
  );
};

export default PublicOffer;
