import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { IMonitor } from '../interfaces/monitor';
import { IRates } from '../interfaces/rates';
import { IInvites } from '../interfaces/invites';
import ApiClient from '../core/apiClient';
import ProjectsStore from './projects';
import MeStore from './me';

class MonitorsStore {
  monitors: IMonitor[] = [];

  rates: IRates[] = [];

  invites: IInvites[] = [];

  limit: number = 10;

  offset: number = 0;

  emailUsers: string[] = [];

  totalCount: number = 0;

  form: any = {};

  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  reachMonitors(monitors:IMonitor[]) {
    if (monitors) {
      monitors.forEach((monitor) => {
        let isUpdated = false;
        this.monitors.map((item) => {
          if (item.id === monitor.id) {
            isUpdated = true;
            return monitor;
          }
          return item;
        });

        if (!isUpdated) {
          this.monitors.push(monitor);
        }
      });
    }
  }

  getRates(currency: string) {
    ApiClient.getRates(currency).then((res) => {
      this.rates = res.data || [];
    });
  }

  getMonitorInvites(id: number) {
    ApiClient.getMonitorInvites(id).then((res) => {
      this.invites = res.data?.relations;
    });
  }

  monitorInvitesRemove(id: number, userId: number) {
    return ApiClient.monitorInvitesRemove(id, userId);
  }

  monitorInvitesAdd(id: number, email: string[]) {
    this.loading = true;
    ApiClient.monitorInvitesAdd(id, [...email.map((item) => item.trim())]).then(() => {
      ApiClient.getMonitorInvites(id).then((res) => {
        this.loading = false;
        this.invites = res.data?.relations;
      });
    }).catch(() => {
      this.loading = false;
    });
  }

  getMonitorInvitesAccept = (id: number) => {
    this.loading = true;
    ApiClient.getMonitorInvitesAccept(id).then(() => {
      MeStore.getMe();
      toast.success('You accepted the request');
    }).catch(() => {
      this.loading = false;
    });
  };

  getMonitorInvitesRefuse = (id: number) => {
    this.loading = true;
    ApiClient.getMonitorInvitesRefuse(id).then(() => {
      MeStore.getMe();
      toast.success('You denied the request');
    }).catch(() => {
      this.loading = false;
    });
  };

  getListFirstMonitors() {
    ApiClient.getListMonitors(this.limit, this.offset).then((res) => {
      this.monitors = res?.data.monitors;
      this.setTotalCount(res?.data.totalCount);
      this.setLoading(false);
    });
  }

  getListMonitors() {
    this.loading = true;
    ApiClient.getListMonitors(this.limit, this.offset).then((res) => {
      this.reachMonitors(res?.data.monitors);
      this.setTotalCount(res?.data.totalCount);
      this.setLoading(false);
    });
  }

  getMonitor(monitorId: number) {
    this.loading = true;
    return ApiClient.getMonitor(monitorId).then((res) => {
      const index = this.monitors.findIndex((monitor) => monitor.id === monitorId);

      if (index !== -1) {
        this.monitors[index] = res.data.monitor;
      } else {
        this.monitors.push(res.data.monitor);
      }

      this.setLoading(false);
    });
  }

  getMonitorsByProject(projectId: number) {
    ApiClient.getMonitorsByProject(projectId).then((r) => this.reachMonitors(r.data.monitors));
  }

  sortMonitorsByProjects(projectId: number) {
    ProjectsStore.setProjectTab(projectId);
    ProjectsStore.setProjectTabName(ProjectsStore.projects.find((p) => p.id === projectId)?.name || '');
  }

  deleteMonitor(id: number) {
    return ApiClient.deleteMonitor(id);
  }

  stopMonitor(id: number) {
    return ApiClient.stopMonitor(id);
  }

  startMonitor(id: number) {
    return ApiClient.startMonitor(id);
  }

  setLoading(state: boolean) {
    this.loading = state;
  }

  setTotalCount(count: number) {
    this.totalCount = count;
  }
}

export default new MonitorsStore();
