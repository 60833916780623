import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainInfo from './MainInfo';
import ProfileTabs from './Tabs';
import MyProfile from './MyProfile';
import Account from './Account';
import Subscriptions from './Subscriptions';
import Notifications from './Notifications';
import Messaging from './Messaging';
import Monitorings from './Monitorings';
import Billing from './Billing';
import MessagingTelegram from './MessagingTelegram';
import BillingSuccess from './BillingSuccess';
import BillingFailed from './BillingFailed';
import MobileProfile from './MobileProfile';

const Profile = () => (
  <Switch>
    <Route path="/profile/menu" exact>
      <MobileProfile />
    </Route>
    <div className="profile">
      <MainInfo />
      <div className="profile-content">

        <ProfileTabs />
        <div className="profile-blocks">
          <Route path="/profile" exact>
            <MyProfile />
          </Route>
          <Route path="/profile/account" exact>
            <Account />
          </Route>
          <Route path="/profile/monitorings" exact>
            <Monitorings />
          </Route>
          <Route path="/profile/subscriptions" exact>
            <Subscriptions />
          </Route>
          <Route path="/profile/billing" exact>
            <Billing />
          </Route>
          <Route path="/profile/billing/success" exact>
            <BillingSuccess />
          </Route>
          <Route path="/profile/billing/failed" exact>
            <BillingFailed />
          </Route>
          <Route path="/profile/notifications" exact>
            <Notifications />
          </Route>
          <Route path="/profile/messaging" exact>
            <Messaging />
          </Route>
          <Route path="/profile/messaging/telegram" exact>
            <MessagingTelegram />
          </Route>
        </div>
      </div>
    </div>
  </Switch>
);

export default Profile;
