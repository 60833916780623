import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Field from './Field';
import Select from './Select';
import ProjectsStore from '../../mobx/projects';
import Input from './Input';
import Icon from '../Icon';

interface SelectProjectsProps {
  onEnter?: any,
  selectChangeHandler?: any,
  setNewProjectName?: any,
  setSelectProjects?: any,
  value?: number,
  error?: boolean,
  errorText?: string,
}

const SelectProjects: React.FC<SelectProjectsProps> = observer(({
  onEnter,
  selectChangeHandler,
  setNewProjectName,
  error,
  errorText,
  value,
  setSelectProjects,
}) => {
  const { t } = useTranslation();
  const [selectProject, setSelectProject] = useState(ProjectsStore.projects.length > 0);
  const [projectName, setProjectName] = useState('');

  useEffect(() => {
    setNewProjectName(projectName);
  }, [projectName]);

  useEffect(() => {
    setProjectName('');
  }, [selectProject]);

  useEffect(() => {
    setSelectProject(ProjectsStore.projects.length > 0);
  }, [ProjectsStore.projects.length]);

  useEffect(() => {
    if (selectProject) {
      setSelectProjects(true);
    } else {
      setSelectProjects(false);
    }
  }, [selectProject]);

  return (
    <div className="select-project">
      <Field label={t('Project')} error={error} errorText={errorText}>
        {selectProject && ProjectsStore.projects.length !== 0 ? (
          <Select
            options={ProjectsStore.projects.map((p) => p.name)}
            onChange={selectChangeHandler}
            defaultValue={ProjectsStore.projects.find((p) => p.id === value ?? ProjectsStore.projectTab)?.name}
          />
        )
          : (
            <Input
              value={projectName}
              onChange={(e: any) => setProjectName(e.target.value)}
              onEnter={onEnter}
            />
          )}
      </Field>
      {ProjectsStore.projects.length !== 0 && (
        <div className="button-add-project" onClick={() => setSelectProject(!selectProject)}>
          {selectProject ? <Icon name="folder" /> : <Icon name="close" /> }
        </div>
      )}
    </div>
  );
});

export default SelectProjects;
