import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

const Tabs: React.FC = observer(() => {
  const tabsName = [
    {
      id: 0,
      name: 'Yarn',
    },
    {
      id: 1,
      name: 'npm',
    },
  ];

  const [tabActive, setTabActive] = useState(0);

  return (
    <div className="tabs">
      {tabsName.map((tab) => (
        <div className={`tab t-button ${tabActive === tab.id ? 'active' : ''}`} onClick={() => setTabActive(tab.id)}>{tab.name}</div>
      ))}
    </div>
  );
});

export default Tabs;
