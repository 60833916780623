import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Information: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="main-content-information">
      <div className="container">
        <div className="main-content-information__inner">
          <div className="main-content-information__item">
            <div className="main-content-information__title t-headline-1">{t('main information title')}</div>
          </div>
          <div className="main-content-information__item">
            <Link to="/sign-up">
              <div className="main-content-information__button button button_bc-indigo t-button">{t('main information button')}</div>
            </Link>
          </div>
          <div className="main-content-information__item">
            <div className="main-content-information__desc t-subtitle-1">{t('main information desc')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
