import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import UseSkipLink from '../../hooks/useSkipLink';

interface MenuMobileProps {
  toggleHandler: any,
  active: boolean,
}

const MenuMobile: React.FC<MenuMobileProps> = ({ toggleHandler, active }) => {
  const { t } = useTranslation();

  return (
    <div className={`menu-mobile ${active ? 'menu-mobile--active' : ''}`}>
      <div className="menu-mobile__close" onClick={() => toggleHandler(false)}>
        <Icon name="close" />
      </div>
      <div className="menu-mobile__content">
        <div className="menu-mobile__item">
          <UseSkipLink className="menu-mobile__link t-headline-3" skipTo="main-content-tutorial">
            <div>{t('menu about')}</div>
          </UseSkipLink>
        </div>
        <div className="menu-mobile__item">
          <UseSkipLink className="menu-mobile__link t-headline-3" skipTo="main-content-price">
            <div>{t('menu monitors')}</div>
          </UseSkipLink>
        </div>
        <div className="menu-mobile__item">
          <UseSkipLink className="menu-mobile__link t-headline-3" skipTo="main-content-price">
            <div>{t('menu pricing')}</div>
          </UseSkipLink>
        </div>
        <div className="menu-mobile__item menu-mobile__item--btn">
          <Link to="/sign-in">
            <div className="menu-mobile__btn button button_bc-main18 t-button">{t('menu sign in')}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MenuMobile;
