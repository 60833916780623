import { t } from 'i18next';

const getHM = (sec: number) => {
  const h = Math.floor(sec / 3600);
  const m = Math.floor(sec % 3600 / 60);

  return `${h}${t('h')} ${m}${t('m')}`;
};

export default getHM;
