import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Input from '../Form/Input';
import Button from '../Button';
import ApiClient from '../../core/apiClient';
import Field from '../Form/Field';
import history from '../../core/history';

const SignUp = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ email: '', password: '', lang: i18n.language === 'ru-RU' ? 'ru' : 'en' });
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [errorTextEmail, setErrorTextEmail] = useState<string>('');
  const [errorTextPassword, setErrorTextPassword] = useState<string>('');

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const submit = () => {
    setLoading(true);
    setErrorTextPassword('');
    setErrorPassword(false);
    setErrorTextEmail('');
    setErrorEmail(false);

    ApiClient.register({ ...form }).then((res: any) => {
      if (res?.data) {
        ApiClient.setToken(res.data?.accessToken, res.data?.refreshToken);
        history.push('/account-ready');
      }

      if (res.response.data.msg) {
        if (typeof res.response.data.msg === 'object') {
          Object.keys(res.response.data.msg).forEach((err: string) => {
            if (err === 'password') {
              setErrorTextPassword(res.response?.data.msg[err][0]);
              setErrorPassword(true);
            } else if (err === 'email') {
              setErrorTextEmail(res.response?.data.msg[err][0]);
              setErrorEmail(true);
            }
          });
        }
      }

      setLoading(false);
    }).catch(() => setLoading(false));
  };

  return (
    <div className="auth-main">
      <div className="auth-main__first-column">
        <div className="sign-up">
          <h2 className="t-headline-2">{t('Sign Up')}</h2>
          <div className="subtitle">
            <p className="t-subtitle-2">{t('Already have an account?')}</p>
            <p className="t-button"><Link to="/sign-in">{t('Sign In')}</Link></p>
          </div>
          <div className="form">
            <Field label={t('Email')} disabled={loading} error={errorEmail} errorText={errorTextEmail}>
              <Input
                name="email"
                placeholder={t('Enter email')}
                icon="email"
                cleanable
                value={form.email}
                onChange={changeHandler}
                onEnter={submit}
              />
            </Field>
            <Field
              label={t('Password')}
              helperText={t('Minimum 8 characters')}
              errorText={errorTextPassword}
              error={errorPassword}
              disabled={loading}
            >
              <Input
                name="password"
                placeholder={t('Enter password')}
                icon="lock"
                cleanable
                value={form.password}
                onChange={changeHandler}
                onEnter={submit}
              />
            </Field>
            <div className="form__agreement t-input">
              {`${t('By clicking button below, you agree to the')} `}
              <Link to="/terms">{t('Terms of Service')}</Link>
              {` ${t('and')} `}
              <Link to="/public-offer">{t('Public Offer')}</Link>
            </div>
            <Button text={t('Sign Up')} size="big" bc="indigo" onClick={submit} loading={loading} />
          </div>
        </div>
      </div>
      <div className="auth-main__second-column" />
    </div>
  );
};

export default SignUp;
