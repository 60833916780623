import React, { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import CheckAuth from '../../core/checkAuth';
import Header from '../Main/Header';
import Welcome from '../Main/Welcome';
import Tutorial from '../Main/Tutorial';
import Monitors from '../../mobx/monitors';
import MeStore from '../../mobx/me';
import { IInvites } from '../../interfaces/invites';

const InviteProject = observer(() => {
  const { t } = useTranslation();
  const [invite, setInvite] = useState<IInvites[] | undefined>();
  const [active, setActive] = useState<boolean>(true);
  const body = document.querySelector('body');

  useEffect(() => {
    CheckAuth.checkLocalStorage();
  }, []);

  useEffect(() => {
    setInvite(MeStore?.me?.invites);
  }, [MeStore?.me?.invites]);

  useEffect(() => {
    const invite = document.querySelector('.invite-project');
    if (body) {
      body.style.overflowY = invite ? 'hidden' : 'auto';
    }
  }, []);

  const handelClose = useCallback(() => {
    setActive(false);
    if (body) {
      body.style.overflowY = 'auto';
    }
  }, []);

  const handelAccept = useCallback((id: number | undefined) => {
    if (id) {
      Monitors.getMonitorInvitesAccept(id);
      if (body) {
        body.style.overflowY = 'auto';
      }
    }
  }, []);

  const handelRefuse = useCallback((id: number | undefined) => {
    if (id) {
      Monitors.getMonitorInvitesRefuse(id);
      if (body) {
        body.style.overflowY = 'auto';
      }
    }
  }, []);

  return (
    <div>
      {active && (
        <div className="invite-project">
          {!CheckAuth.isAuthenticated && (
            <div className="main">
              <Header />
              <div className="main-content">
                <Welcome />
                <Tutorial />
              </div>
            </div>
          )}
          {invite?.[0] && CheckAuth.isAuthenticated ? (
            <div className="invite-project__bg">
              <div className="invite-project__popup">
                <div className="invite-project__close" onClick={handelClose}>
                  <Icon name="close" />
                </div>
                <div className="invite-project__title t-headline-2">{invite?.[0]?.monitor?.user?.name ?? invite?.[0]?.monitor?.user?.email} {t('invited you to join a project')} {invite?.[0]?.monitor?.name}</div>
                <div className="invite-project__buttons">
                  <div className="invite-project__sign-in button_bc-indigo" onClick={() => handelAccept(invite?.[0]?.monitor_id)}>{t('Accept')}</div>
                  <div className="invite-project__sign-up button button_bc-main2" onClick={() => handelRefuse(invite?.[0]?.monitor_id)}>{t('Refuse')}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="invite-project__bg">
              <div className="invite-project__popup">
                <div className="invite-project__title t-headline-2">{t('You were invited join the project')}</div>
                <div className="invite-project__desc t-body-1">{t('But first you need to sign in or sign up')}</div>
                <div className="invite-project__buttons">
                  <Link to="/sign-in" className="invite-project__sign-in button_bc-indigo"><Icon name="sign-in-out" /><span className="t-button">{t('Sign In')}</span></Link>
                  <Link to="/sign-up" className="invite-project__sign-up button button_bc-main2"><span className="t-button">{t('Sign Up')}</span></Link>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default InviteProject;
