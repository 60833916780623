const getColor = (string: string) => {
  let sum = 0;
  for (let i = 0; i < string.length; i++) {
    const charCode = string.charCodeAt(i);
    sum += charCode;
  }
  return `color${sum % 13}`;
};

export default getColor;
