import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FSPopup from '../FSPopup';
import Field from '../Form/Field';
import Input from '../Form/Input';
import SelectProjects from '../Form/SelectProjects';
import Button from '../Button';
import ProjectsStore from '../../mobx/projects';
import Alert from '../Alert';
import Icon from '../Icon';
import Tabs from './Tabs';

const AgentMonitor = () => {
  const { t } = useTranslation();
  const [newProjectName, setNewProjectName] = useState('');
  const [selectProject, setSelectProject] = useState(false);
  const [form, setForm] = useState({
    url: '', name: '', project_id: -1, type: 'avail',
  });
  const [isLoading, setIsLoading] = useState(false);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const selectChangeHandler = (value: string) => {
    setForm({ ...form, project_id: ProjectsStore.projects.filter((p) => p.name === value)[0]?.id });
  };

  const onCreate = () => {
    setIsLoading(true);
    if (newProjectName.length) {
      // eslint-disable-next-line no-console
    }

    return selectProject;
  };

  const handleClick = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <FSPopup back={t('Back')}>
      <div className="agent-monitoring">
        <div className="agent-monitoring__title t-headline-2">{t('OpenSource Server Agent')}</div>
        <div className="agent-monitoring__desc t-input">{t('Follow instructions to setup monitoring')}</div>
        <form>
          <div className="agent-monitoring__block">
            <div className="agent-monitoring__block-title">1. {t('Select project and enter title')}</div>
            <SelectProjects
              selectChangeHandler={selectChangeHandler}
              setNewProjectName={setNewProjectName}
              setSelectProjects={setSelectProject}
            />
            <Field label={t('Title')}>
              <Input
                placeholder={t('Enter title')}
                value={form.name}
                onChange={changeHandler}
                name="name"
                onEnter={onCreate}
              />
            </Field>
          </div>
          <div className="agent-monitoring__block">
            <div className="agent-monitoring__block-title">2. {t('Enter server address')}</div>
            {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
            <div className="agent-monitoring__block-inner">
              <p className="agent-monitoring__block-text t-body-2">{t('You can enter server URL (for example google.com)')}<br />{t('or IP (for example 255.255.255.255).')}</p>
              <p className="agent-monitoring__block-text t-body-2">{t('After you enter, press button below to check server connection')}</p>
            </div>
            <Field label={t('Website address')}>
              <Input
                placeholder={t('Enter website address')}
                value={form.url}
                onChange={changeHandler}
                name="websiteAddress"
                onEnter={onCreate}
              />
            </Field>
            <div className="server-address-test">
              <Button text={t('Test')} bc="main2" size="big" />
              <Alert type="success" title={t('Server is connected!')} size="small" />
            </div>
          </div>
          <div className="agent-monitoring__block">
            <div className="agent-monitoring__block-title">3. {t('Download server agent')}</div>
            <div className="agent-monitoring__block-inner">
              <p className="agent-monitoring__block-text t-body-2">{t('Our server agent is opensource program. You can check repository.')} <br />
                <a href="#" className="agent-monitoring__share" rel="noreferrer" target="_blank">{t('Go to Github repository')}
                  <Icon name="share" />
                </a>
              </p>
              <p className="agent-monitoring__block-text t-body-2">{t('Open your terminal and use the following command to download agent.')}</p>
            </div>
            <div className="block-copy">
              <div className="block-copy__item" onClick={() => handleClick('git clone git@github.com:whatcha/server-agent.git')}>
                <div className="block-copy__item-text">git clone git@github.com:whatcha/server-agent.git</div>
                <div className="block-copy__copy">
                  <Icon name="copy" />
                </div>
              </div>
            </div>
          </div>
          <div className="agent-monitoring__block">
            <div className="agent-monitoring__block-title">4. {t('Install dependencies and start agent')}</div>
            <div className="agent-monitoring__block-inner">
              <p className="agent-monitoring__block-text t-body-2">{t('From the same terminal move into agent folder and install dependencies')}</p>
            </div>
            <Tabs />
            <div className="block-copy">
              <div className="block-copy__item block-copy__item_copy" onClick={() => handleClick('cd server-agent')}>
                <div className="block-copy__item-text">cd server-agent</div>
                <div className="block-copy__copy">
                  <Icon name="copy" />
                  <p className="block-copy__copy-text">{t('Copied!')}</p>
                </div>
              </div>
              <div className="block-copy__item" onClick={() => handleClick('yarn install')}>
                <div className="block-copy__item-text">yarn install</div>
                <div className="block-copy__copy">
                  <Icon name="copy" />
                </div>
              </div>
              <div className="block-copy__item" onClick={() => handleClick('yarn start server-agent')}>
                <div className="block-copy__item-text">yarn start server-agent</div>
                <div className="block-copy__copy">
                  <Icon name="copy" />
                </div>
              </div>
            </div>
          </div>
          <div className="agent-monitoring__block">
            <div className="agent-monitoring__block-title">5. {t('Check agent availability')}</div>
            <div className="agent-monitoring__block-inner">
              <p className="agent-monitoring__block-text t-body-2">{t('Finally, you need to check if agent is responds to our requests')}</p>
            </div>
            <div className="server-address-test">
              <Button text={t('Test')} bc="main2" size="big" />
              <Alert type="success" title={t('Agent is responding!')} size="small" />
            </div>
          </div>
          <div className="agent-monitoring__block">
            <Button text={t('Add monitoring')} bc="main18 last" size="big" onClick={onCreate} loading={isLoading} />
          </div>
        </form>
      </div>
    </FSPopup>
  );
};

export default AgentMonitor;
