import React from 'react';

import Auth from '../components/Auth/Auth';
import AppContainer from '../components/App/Container';

export const useRoutes = (isAuthenticated: null | boolean) => {
  if (isAuthenticated === false) {
    return <Auth />;
  }

  if (isAuthenticated === true) {
    return <AppContainer />;
  }

  return null;
};
