import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NotificationsStore from '../../mobx/notifications';
import ApiClient from '../../core/apiClient';
import EventsStore from '../../mobx/events';
import ProjectsStore from '../../mobx/projects';
import Icon from '../Icon';

const MenuFixed = observer(() => {
  const [unread, setUnread] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (EventsStore.events) {
      const unreadMessage = EventsStore.events.filter((event) => event.isNew);
      EventsStore.setEventsNew(unreadMessage.length);
      setUnread(unreadMessage.length > 0);
    } else {
      setUnread(false);
    }
  }, [EventsStore.events, EventsStore.eventsNew]);

  const eventClick = () => {
    NotificationsStore.open = !NotificationsStore.open;
    setTimeout(() => {
      ApiClient.readEvents().then();
      EventsStore.getListEvents();
    }, 3500);
  };

  const handleClick = () => {
    ProjectsStore.changeTab(0);
  };

  return (
    <div className="menu-fixed">
      <Link to="/" className="menu-fixed__item" onClick={handleClick}>
        <div className="menu-fixed__icon">
          <Icon name="menu-dashboard" />
        </div>
        <div className="menu-fixed__text">{t('Dashboard')}</div>
      </Link>
      <div className="menu-fixed__item" onClick={eventClick}>
        <div className="menu-fixed__icon">
          <Icon name="bell" />
          {unread && <span className="menu-fixed__event-count">{EventsStore.eventsNew}</span>}
        </div>
        <div className="menu-fixed__text">{t('Events')}</div>
      </div>
      <Link to="/profile/menu" className="menu-fixed__item">
        <div className="menu-fixed__icon">
          <Icon name="profile" />
        </div>
        <div className="menu-fixed__text">{t('Profile')}</div>
      </Link>
      <Link to="/select-monitoring" className="menu-fixed__item">
        <div className="menu-fixed__icon">
          <Icon name="menu-plus" />
        </div>
        <div className="menu-fixed__text menu-fixed__text--blue">{t('Add monitoring')}</div>
      </Link>
    </div>
  );
});

export default MenuFixed;
