import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ApiClient from '../../core/apiClient';
import Icon from '../Icon';
import CheckAuth from '../../core/checkAuth';
import Popup from '../Popup';
import Button from '../Button';

const ProfileTabs = () => {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const handelOpenPopup = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setPopup(!popup);
  };

  const logoutClick = () => {
    setLoading(true);

    ApiClient.logout().then(() => {
      CheckAuth.logout();

      setLoading(false);
    }).catch(() => setLoading(false));
  };

  return (
    <div className="profile-tabs">
      <NavLink to="/profile" exact activeClassName="active">
        <div className="tab"><Icon name="profile" />{t('Profile')}</div>
      </NavLink>
      <NavLink to="/profile/account" exact activeClassName="active">
        <div className="tab"><Icon name="account" />{t('Account')}</div>
      </NavLink>
      <NavLink to="/profile/monitorings" exact activeClassName="active">
        <div className="tab"><Icon name="monitorings" />{t('Monitorings')}</div>
      </NavLink>
      <div className="separators" />
      {/*<NavLink to="/profile/subscriptions" exact activeClassName="active">*/}
      {/*  <div className="tab"><Icon name="subscriptions" />Subscriptions</div>*/}
      {/*</NavLink>*/}
      <NavLink to="/profile/billing" exact activeClassName="active">
        <div className="tab"><Icon name="billing" />{t('Billing')}</div>
      </NavLink>
      <div className="separators" />
      <NavLink to="/profile/notifications" exact activeClassName="active">
        <div className="tab"><Icon name="bell" />{t('Notifications')}</div>
      </NavLink>
      <NavLink to="/profile/messaging" exact activeClassName="active">
        <div className="tab"><Icon name="messaging" />{t('Messaging')}</div>
      </NavLink>
      <div className="separators" />
      <div className="tab" onClick={handelOpenPopup}>
        <Icon name="logout" />{t('Log Out')}
      </div>
      {popup ? (
        <Popup onClose={handelOpenPopup}>
          <div className="popup-logOut">
            <p className="t-headline-3">{t('Are you sure you want to log out of your account?')}</p>
            <div className="buttons">
              <Button
                text={t('Log Out')}
                size="big"
                bc="red"
                loading={loading}
                onClick={() => logoutClick()}
              />
              <Button text={t('Cancel')} size="big" bc="transparent" onClick={() => handelOpenPopup()} />
            </div>
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

export default ProfileTabs;
