import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import {
  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MonitorStore from '../../mobx/monitors';
import Icon from '../Icon';
import ProjectsStore from '../../mobx/projects';
import Button from '../Button';
import Alert from '../Alert';
import MeStore from '../../mobx/me';
import OverviewAvail from './OverviewAvail';
import OverviewAgent from './OverviewAgent';

const MonitorPage = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const monitorId = history.location.pathname.replace(/\D+/, '');
  let monitor = MonitorStore.monitors.find((m) => m.id === Number(monitorId));
  const timeAfter = moment(monitor?.checked_at).utc(true).fromNow();
  const nameProject = ProjectsStore.projects.filter((project) => project.id === monitor?.project_id)[0]?.name;

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const [isLoading, setLoading]: any = useState(false);

  const startMonitor = () => {
    if (monitor?.id) {
      MonitorStore.loading = true;
      MonitorStore.startMonitor(monitor?.id).then((res: any) => {
        if (!res?.response?.status) {
          toast.success(t('Monitor started'));
          MonitorStore.monitors.filter((m) => m.id === monitor?.id)[0].status = 0;
        }
      }).finally(() => {
        MonitorStore.loading = false;
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    MonitorStore.getMonitor(Number(monitorId)).then(() => {
      monitor = MonitorStore.monitors.find((m) => m.id === Number(monitorId));
      setLoading(false);
    });
  }, [monitorId]);

  return (
    <div className={`monitor-page ${monitor?.status === 1 ? 'monitor-page--paused' : ''}  ${monitor?.status === 2 ? 'monitor-page--stopped' : ''}`}>
      <div className="top-line">
        <div className="back" onClick={() => history.goBack()}>
          <Icon name="arrow" />
        </div>
        <div className="monitor-info">
          <div className="monitor__name t-headline-2">{monitor?.name}</div>
        </div>
        <div className="notification-setting">
          <Icon name="bell" />
          <div className="t-button">{t('Mute notification')}</div>
        </div>
        <div className="monitor-options">
          <Icon name="menu-dots" />
        </div>
      </div>
      {!isLoading && (
        <div>
          <div className="line-information">
            <div className="line-information__item t-subtitle-2">
              {nameProject && (<div className="line-information__col t-subtitle-2">{nameProject}</div>)}
              {monitor?.type && (<div className="line-information__col t-subtitle-2">{t(monitor?.type || '')}</div>)}
              <div className="line-information__icon">
                <Icon name="dots-three" />
              </div>
              {monitor?.status === 1 && (
                <div className="line-information__col line-information__col--sync t-subtitle-2">{t('Sync paused')}</div>
              )}
              {monitor?.status === 2 && (
                <div className="line-information__col line-information__col--sync t-subtitle-2">{t('Sync stopped')}</div>
              )}
              {monitor?.status === 0 && (
                <div className="line-information__col line-information__col--sync t-subtitle-2">{t('Last sync')} {timeAfter}</div>
              )}
            </div>
            <div className="line-information__item t-subtitle-2">{monitor?.url} <Icon name="copy-black" /></div>
          </div>
          {monitor?.status === 1 && MeStore?.me?.status !== 1 && (
            <div className="monitor-sync">
              <div className="monitor-sync__title t-headline-4">{t('Sync stopped')}</div>
              <div className="monitor-sync__desc t-body-2">Запустить синхронизацию, чтобы отследивать состояние вашего сервера</div>
              <Button
                text="Запустить синхронизацию"
                size="big"
                bc="main18"
                loading={MonitorStore.loading}
                onClick={startMonitor}
              />
            </div>
          )}
          {MeStore?.me?.status === 1 && (
            <Alert type="warning" title={t('Синхронизация приостановлена')} description="У вас не хватает средств на балансе. Чтобы восстановить синхронизацию, пополните баланс." />
          )}
          <div className="monitor-blocks">
            {monitor?.type === 'agent' ? (
              <OverviewAgent monitor={monitor!!} />
            ) : (
              <OverviewAvail monitor={monitor!!} />
            )}
            <div className="uptime">
              <div className="t-headline-4">{t('Uptime')}</div>
              <div className="uptime-chart">
                {monitor?.statistic?.common && monitor?.statistic.common.map((stat: { state: string }) => <div className={`chart-day ${Number(stat.state) === 1 ? 'green' : 'red'}`}><div /></div>)}
              </div>
              <div className="uptime-chart-date">
                {monitor?.statistic?.common && monitor.statistic.common.length > 1 && <div className="day-start">{monitor?.statistic?.common.length > 30 ? 30 : monitor?.statistic?.common.length - 1} {t('days ago')}</div>}
                <div className="day-end">{t('Today')}</div>
              </div>
            </div>
            {monitor?.statistic?.la1 && monitor?.statistic?.la5 && monitor?.statistic?.la15 && (
              <div className="cpu">
                <div className="t-headline-4">{t('CPU Usage')} <span>24 hour</span></div>
                <div className="chart-bar-container">
                  <Line
                    options={options}
                    data={
                      {
                        labels: monitor?.statistic?.la1.map((item: { created_at: string; }) => moment(item.created_at).format('HH:00')),
                        datasets: [
                          {
                            fill: true,
                            data: monitor?.statistic?.la1.map((item: { max: string; }) => Number(item.max) * 100),
                            borderColor: 'rgba(114, 46, 209, 1)',
                            backgroundColor: 'rgba(114, 46, 209, 0.2)',
                          },
                          {
                            fill: true,
                            data: monitor?.statistic?.la5.map((item: { max: string; }) => Number(item.max) * 100),
                            borderColor: 'rgba(114, 46, 209, 1)',
                            backgroundColor: 'rgba(114, 46, 209, 0.2)',
                          },
                          {
                            fill: true,
                            data: monitor?.statistic?.la15.map((item: { max: string; }) => Number(item.max) * 100),
                            borderColor: 'rgba(114, 46, 209, 1)',
                            backgroundColor: 'rgba(114, 46, 209, 0.2)',
                          },
                        ],
                      }
                    }
                    datatype="line"
                  />
                </div>
              </div>
            )}
            {monitor?.statistic?.memory_available && monitor?.statistic?.memory_free && monitor?.statistic?.memory_total && (
              <div className="ram">
                <div className="t-headline-4">{t('RAM Usage')} <span>24 hour</span></div>
                <div className="chart-bar-container">
                  <Line
                    options={options}
                    data={
                      {
                        labels: monitor?.statistic?.memory_available.map((item: { created_at: string; }) => moment(item.created_at).format('HH:00')),
                        datasets: [
                          {
                            fill: true,
                            data: monitor?.statistic?.memory_available.map((item: { max: string; }) => Number(item.max) / 1024 / 1024),
                            borderColor: 'rgb(47,84,235)',
                            backgroundColor: 'rgba(47,84,235, 0.2)',
                          },
                          {
                            fill: true,
                            data: monitor?.statistic?.memory_free.map((item: { max: string; }) => Number(item.max) / 1024 / 1024),
                            borderColor: 'rgb(47,84,235)',
                            backgroundColor: 'rgba(47,84,235, 0.2)',
                          },
                          {
                            fill: true,
                            data: monitor?.statistic?.memory_total.map((item: { max: string; }) => Number(item.max) / 1024 / 1024),
                            borderColor: 'rgb(47,84,235)',
                            backgroundColor: 'rgba(47,84,235, 0.2)',
                          },
                        ],
                      }
                    }
                    datatype="line"
                  />
                </div>
              </div>
            )}
            {monitor?.statistic?.disk_sectors_read && monitor?.statistic?.disk_sectors_write && (
              <div className="disk">
                <div className="t-headline-4">{t('Disk Usage')} <span>24 hour</span></div>
                <div className="chart-bar-container">
                  <Line
                    options={options}
                    data={
                      {
                        labels: monitor?.statistic?.disk_sectors_read.map((item: { created_at: string; }) => moment(item.created_at).format('HH:00')),
                        datasets: [
                          {
                            fill: true,
                            data: monitor?.statistic?.disk_sectors_read.map((item: { max: string; }) => Number(item.max) / 1024 / 1024),
                            borderColor: 'rgb(19,194,194)',
                            backgroundColor: 'rgba(19,194,194, 0.2)',
                          },
                          {
                            fill: true,
                            data: monitor?.statistic?.disk_sectors_write.map((item: { max: string; }) => Number(item.max) / 1024 / 1024),
                            borderColor: 'rgb(19,194,194)',
                            backgroundColor: 'rgba(19,194,194, 0.2)',
                          },
                        ],
                      }
                    }
                    datatype="line"
                  />
                </div>
              </div>
            )}
            {monitor?.statistic?.rx_bytes && monitor?.statistic?.tx_bytes && (
              <div className="network">
                <div className="t-headline-4">{t('Network')} <span>24 hour</span></div>
                <div className="chart-bar-container">
                  <Line
                    options={options}
                    data={
                      {
                        labels: monitor?.statistic?.rx_bytes.map((item: { created_at: string; }) => moment(item.created_at).format('HH:00')),
                        datasets: [
                          {
                            fill: true,
                            data: monitor?.statistic?.rx_bytes.map((item: { max: string; }) => Number(item.max) / 1024 / 1024),
                            borderColor: 'rgb(250,84,28)',
                            backgroundColor: 'rgba(250,84,28, 0.2)',
                          },
                          {
                            fill: true,
                            data: monitor?.statistic?.tx_bytes.map((item: { max: string; }) => Number(item.max) / 1024 / 1024),
                            borderColor: 'rgb(250,84,28)',
                            backgroundColor: 'rgba(250,84,28, 0.2)',
                          },
                        ],
                      }
                    }
                    datatype="line"
                  />
                </div>
              </div>
            )}
            {monitor?.statistic?.disk_all && monitor?.statistic?.disk_free && monitor?.statistic?.disk_used && (
              <div className="disk-use">
                <div className="t-headline-4">{t('Disk Space')} <span>24 hour</span></div>
                <div className="chart-bar-container">
                  <Line
                    options={options}
                    data={
                      {
                        labels: monitor?.statistic?.disk_all.map((item: { created_at: string; }) => moment(item.created_at).format('HH:00')),
                        datasets: [
                          {
                            fill: true,
                            data: monitor?.statistic?.disk_all.map((item: { max: string; }) => Number(item.max) / 1024 / 1024 / 1024),
                            borderColor: 'transparent',
                            backgroundColor: 'transparent',
                          },
                          {
                            fill: true,
                            data: monitor?.statistic?.disk_used.map((item: { max: string; }) => Number(item.max) / 1024 / 1024 / 1024),
                            borderColor: 'rgb(82,196,26)',
                            backgroundColor: 'rgba(82,196,26,0.2)',
                          },
                        ],
                      }
                    }
                    datatype="line"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default MonitorPage;
