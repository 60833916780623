import React from 'react';
import { useTranslation } from 'react-i18next';
import FSPopup from '../FSPopup';

const HelpSuccess = () => {
  const { t } = useTranslation();

  return (
    <FSPopup back={t('Return')}>
      <div className="auth-popup">
        <div className="auth-popup__image">
          <div className="auth-popup__icon account-ready" />
        </div>
        <div className="t-headline-2">{t('Your message have sent!')}</div>
      </div>
    </FSPopup>
  );
};

export default HelpSuccess;
