import React from 'react';
import { useTranslation } from 'react-i18next';
import UseSkipLink from '../../hooks/useSkipLink';

const Nav: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="main-header-nav">
      <UseSkipLink className="main-header-nav__item t-button" skipTo="main-content-tutorial">
        <div>{t('main about')}</div>
      </UseSkipLink>
      <UseSkipLink className="main-header-nav__item t-button" skipTo="main-content-price">
        <div className="main-header-nav__item t-button">{t('main monitors')}</div>
      </UseSkipLink>
      <UseSkipLink className="main-header-nav__item t-button" skipTo="main-content-price">
        <div className="main-header-nav__item t-button">{t('main pricing')}</div>
      </UseSkipLink>
    </div>
  );
};

export default Nav;
